import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumbs from '../../components/Header/Breadcrumbs';
import Typography from '@mui/joy/Typography';
import CreateModal from '../../components/ActionModals/CreateModal';
import { IconButton, Skeleton } from '@mui/joy';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

// icons
import AddIcon from '@mui/icons-material/Add';
import ListItems from '../../components/ItemList/ItemList';
import Template from '../../services/Template';

import BaseView from '../BaseView';
import InfoModal from '../../components/ActionModals/InfoModal';
import { getClientId } from '../../utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useClient } from '../../context/ClientContext';


export default function MailTemplate() {
    const { t } = useTranslation();
    const client = useClient()
    const navigate = useNavigate();
    const {getAccessTokenSilently} = useAuth0()
    const [mails, setMails] = useState<{ id: number, name: string }[]>([]);
    const [loading, setLoading] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [errorOnCreate, setErrorOnCreate] = useState('')

    

    React.useEffect(() => {
       
        setLoading(true)
        const fetchData = async () => {
            Template.getMail(getAccessTokenSilently, client.client!.id)
                .then(data => {
                    setMails(data)
                }).catch(err => {
                    console.error(err)
                }).finally(() => {
                    setLoading(false)
                })

        }
        fetchData()
    }, []);


    const openNewFormDialog = () => {
        setOpenDialog(true)
    }

    const closenNewFormDialog = () => {
        setOpenDialog(false)
    }



    const newMail = async (name: string) => {

        const clientId  = await getClientId()

        await Template.createMail(getAccessTokenSilently, name, "", "", clientId)
            .then(data => {
                const mail_id = data.id
                const url = `/mail/${mail_id}`;
                navigate(url);
            }).catch(err => {
                setErrorOnCreate(t("Ya existe un elemento con ese nombre"))
                console.error(err)
            }).finally(() => {
            })

    };


    const selectMail = (id: number) => {

        const url = `/mail/${id}`;
        navigate(url);
    };


    //const saveStrategy = async (id: number, editedRow: string) => {
    //    const response = await Planification.editPlanification({ planification_id: id, name: editedRow })
    //    return response
    //};

    const deleteMail = async (id: number) => {
    
        return await Template.deleteMail(getAccessTokenSilently, id)
    };

    return (
        <BaseView view="/mail/">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width:'100%' }}>
                <Breadcrumbs elements={[
                    { "name": t("Emails") },
                ]} />
                <IconButton aria-label="settings" onClick={()=>setInfoOpen(true)}>
                    <InfoRoundedIcon />
                </IconButton>
            </Box >
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2"> {t("Emails")} </Typography>
                <Button
                    onClick={() => openNewFormDialog()}
                    color="primary"
                    startDecorator={<AddIcon />}
                    size="sm"
                >
                    {t("Nuevo email")}
                </Button>
                <CreateModal
                    open={openDialog}
                    onClose={closenNewFormDialog}
                    onSubmit={newMail}
                    error={errorOnCreate}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                my: 1,
                gap: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
            }}>
                {loading ? (
                    <>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <Skeleton key={index} animation="wave" level="h2" variant="text" />
                        ))}
                    </>
                ) : (
                    <ListItems
                        items={mails}
                        onSelect={selectMail}
                        onDelete={deleteMail}
                    />
                )}
            </Box>
            <InfoModal
            isOpen={infoOpen}
            content={t('Elabora de manera eficiente plantillas de correo electrónico profesionales para tus comunicaciones de recolección de deudas.')}
            onClose={()=>setInfoOpen(false)}
            />
        </BaseView>
        
    );
}
