import { PropsWithChildren } from 'react'
import PermissionsGate from '../components/PermissionsGate'

interface BaseViewProps {
    view: string
}

export default function BaseView(props: PropsWithChildren<BaseViewProps>) {
    return (
        <PermissionsGate target={props.view} operation="">
            {props.children}
        </PermissionsGate>
    )
}