import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { AccessTimeRounded } from "@mui/icons-material";
import { Divider } from "@mui/joy";

export default function DelayNodeComponent({ delay }: { delay: string }) {

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <Box sx={{ mr: 1, display: 'flex' }}>
                    <AccessTimeRounded />
                </Box>
                <Divider orientation="vertical" />
                <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>
                    {delay}
                </Typography>
            </Box>
        </>
    )
}