import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import BasicAlert from '../Alert';

export interface CreateModalProps {
    open: boolean;
    onSubmit: (name: string) => void;
    onClose: () => void;
    error: string;
}

export default function CreateModal(props: CreateModalProps) {
    const { t } = useTranslation();

    const { open, onSubmit, onClose, error } = props;
    const [name, setName] = React.useState('');
    const [alert, setAlert] = React.useState({ open: false, severity: '', text: '' });

    React.useEffect(() => {
        if (error !== '') {
            setAlert({ open: true, severity: 'error', text: error });
        }
    }, [error]);

    const handleSubmit = () => {
        onSubmit(name);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog>
                <DialogTitle>{t("Crear nuevo elemento")}</DialogTitle>
                <DialogContent>{t("Específique el nombre del nuevo elemento")}</DialogContent>
                <BasicAlert
                    open={alert.open}
                    severity={alert.severity}
                    text={alert.text}
                    onCloseAlert={() => setAlert({ open: false, severity: '', text: '' })}
                />
                <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>{t("Nombre")}</FormLabel>
                            <Input
                                autoFocus
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <Button type="submit">{t("Crear")}</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal >
    );
}
