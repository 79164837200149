import React, { createContext, useState, useContext, useEffect } from 'react';
import PlanificationEvents from '../services/Drawflow';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';

export const ContentTypeContext = createContext({ contentTypeMapping: {} });

type ContentTypeMapping = {
    [key: string]: number;
};


export const ContentTypeProvider = ({ children }: { children: React.ReactNode }) => {
    const [contentTypeMapping, setContentTypeMapping] = useState<ContentTypeMapping>({
        mail: 0,
        sms: 0,
        call: 0
    });
    const [isLoading, setIsLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();
    const getContentTypes = async () => {
        try {
            const updatedMapping: ContentTypeMapping = { ...contentTypeMapping };
            for (const [modelName] of Object.entries(contentTypeMapping)) {
                const response = await PlanificationEvents.getContentType(getAccessTokenSilently, modelName);
                updatedMapping[modelName] = response;
            }
            setContentTypeMapping(updatedMapping);
        } catch (error) {
            console.error('Error fetching content types:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getContentTypes();
    }, []);

    if (isLoading) {
        return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        )
        ;
    }

    return (
        <ContentTypeContext.Provider value={{ contentTypeMapping }}>
            {children}
        </ContentTypeContext.Provider>
    );
};

export function useContentType() {
    const context = useContext(ContentTypeContext);
    if (!context) {
        throw new Error('useContentType must be used within a ContentTypeProvider');
    }
    return context;
}
