import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from "react-i18next";

import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import Grid from '@mui/material/Grid/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { blue, orange, green, grey, red } from '@mui/material/colors';

import EventAvatar from './EventAvatar';



export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  selectedEvents: Record<string, any>[];
  timezone: string;
}

export interface SimpleDialogElementProps {
    event: Record<string, any>;
    timezone: string;
}

export interface DayDetailBoxProps {
    open: boolean;
    selectedValue: string;
    selectedEvents: Record<string, any>[];
    timezone: string;
  }
  


function SimpleDialogElement(props: SimpleDialogElementProps) {
    const { event, timezone } = props;

    function formatDate (date: Date) {
        const options: Intl.DateTimeFormatOptions = {
            timeZone: timezone,
            hour: "2-digit",
            minute:"2-digit",
            timeZoneName: "short"
        };
        return date.toLocaleTimeString("es-ES", options);
    }

    return (
        <>
        <ListItemAvatar>
            <EventAvatar actionType={event.action_type}/>
        </ListItemAvatar>
        <ListItemText secondary={formatDate(event.timestamp)} primary={event.action_name} />
        </>
    );
}


function DayDetailBox(props: DayDetailBoxProps) {
    const { t } = useTranslation();
    const { selectedValue, open, selectedEvents, timezone } = props;

    const handleListItemClick = (value: string) => {
        value; //TODO
    };

    if (open) {
        return (
            <Box sx={{ maxHeight: '320' }}>
                <Typography variant="h6" gutterBottom> {t("Eventos programados")}: {selectedValue} </Typography>
                <Box sx={{ maxHeight: '280', overflow: 'auto' }}>
                    <List sx={{ pt: 0 }}>
                        {selectedEvents.map((event) => (
                            <ListItem disableGutters key={event.id}>
                            <ListItemButton onClick={() => handleListItemClick(event.action_name)}>
                                <SimpleDialogElement event={event} timezone={timezone}/>
                            </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        );
    } else {
        return (
        <>
            <Box>
            </Box>
        </>
        );
    }
}

const isWeekend = (date: Dayjs) => {
    const day = date.day();
  
    return day === 0 || day === 6;
};

const isHoliday = (date: Dayjs, holidays: Array<Record<string, any>>) => {
    var auxDate = date.toDate();

    for (let i = 0; i < holidays.length; i++) {
        if (holidays[i]["start"].toLocaleDateString() === auxDate.toLocaleDateString()) {
            return true;
        }
    }
  
    return false;
};
  


function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: Record<string, number>[], holidays?: Record<string, any>[]}
) {
    const { holidays = [], highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const colors : {[key: string]: string} = {
        "Mail": blue[600],
        "Sms": orange[600],
        "Call": green[600]
    };
    //const positions = [20, 30, 10];

    let counts;
    let numTypes = 0;
    if (!props.outsideCurrentMonth) {
        counts = highlightedDays[props.day.date()-1];

        if (counts)
            numTypes = Object.values(counts).reduce((counter, elem) => {
                if (elem > 0) {
                    return counter + 1;
                }
                return counter;
            }, 0);
    }

    const positions = new Set();
    for (let i = numTypes-1; i > -1; i-=2) {
        positions.add(20+5*i);
        positions.add(20-5*i);
    }

    let ret = <PickersDay 
                    {...other} 
                    outsideCurrentMonth={outsideCurrentMonth} 
                    day={day}
                    sx={[
                        {[`&&.${pickersDayClasses.selected}`]: {
                          backgroundColor: grey[400]
                        }},
                        isWeekend(day) && {color: red[400]},
                        isHoliday(day, holidays) && {
                            color: red[800],
                            fontWeight: 'bold'
                        },
                      ]}
                />;

    let positionsIt = positions.values();
    for (const key in counts) {
        let count = counts[key];

        if (count > 0) {
            let color = colors[key];
            let position = positionsIt.next().value;
    
            ret = (
                <Badge
                    overlap="circular"
                    sx={{
                        "& .MuiBadge-badge": {
                            backgroundColor: color,
                            right: `${position}px`,
                        }
                        }}
                    variant="dot"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    {ret}
                </Badge>
            )
        }
    }
    
    return (ret);
}

interface CampaignCalendarProps {
    events?: Array<Record<string, any>>
    holidays?: Array<Record<string, any>>
    timezone?: string
    detailOpen?: boolean
    onClickDay?: (value: dayjs.Dayjs | null) => void
    defaultValue?: dayjs.Dayjs,
    shouldDisableDate?: (day: dayjs.Dayjs) => boolean
}


export default function CampaignCalendar(props: CampaignCalendarProps) {

    const handleDaySelect = (value: dayjs.Dayjs | null) => {
        let auxEvents: Record<string, any>[] = [];
        for(let i = 0; i < events.length; i++) {
            let timestamp: Date = events[i]["timestamp"];

            if (timestamp.getDate() == value?.date() &&
                timestamp.getMonth() == value?.month() &&
                timestamp.getFullYear() == value?.year()
            ) {
                auxEvents.push(events[i]);
            }
        }

        auxEvents = auxEvents.sort((a, b) => (a.timestamp - b.timestamp));

        setSelectedEvents(auxEvents);
        if (value)
            setSelectedValue(value.toDate().toLocaleDateString("es-ES", {timeZone: timezone}));
    };

    const {
        events=[],
        holidays=[],
        timezone="Europe/Madrid",
        detailOpen=true,
        onClickDay= () => void 0,
        defaultValue=dayjs(),
        shouldDisableDate= () => false
    } = props;

    const requestAbortController = React.useRef<AbortController | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [highlightedDays, setHighlightedDays] = React.useState([{}]);
    const [open, setOpen] = React.useState(false);
    const [_, setInitialDate] = React.useState(dayjs(new Date()));
    const [selectedValue, setSelectedValue] = React.useState("");
    const [selectedEvents, setSelectedEvents] = React.useState<Record<string, any>[]>([]);

    const fetchHighlightedDays = (date: Dayjs) => {
        const controller = new AbortController();

        let daysToHighlight = []

        for(let i = 1; i <= date.daysInMonth(); i++) {
            let counts: Record<string, number> = {
                "Mail": 0,
                "Sms": 0,
                "Call": 0
            };

            for(let j = 0; j < events.length; j++) {
                let timestamp: Date = events[j]["timestamp"];

                if (timestamp.getDate() == i &&
                    timestamp.getMonth() == date.month() &&
                    timestamp.getFullYear() == date.year()
                ) {
                    counts[events[j]["action_type"]]++;
                }
            }

            daysToHighlight.push(counts);
        }
        setHighlightedDays(daysToHighlight);

        requestAbortController.current = controller;
    };

    React.useEffect(() => {
        let initialValue = defaultValue;

        setInitialDate(initialValue);
        handleDaySelect(initialValue);
        fetchHighlightedDays(initialValue);
        setOpen(detailOpen);

        // abort request on unmount
        return () => requestAbortController.current?.abort();
    }, [events, detailOpen]);

    const handleMonthChange = (date: Dayjs) => {
        if (requestAbortController.current) {
            // make sure that you are aborting useless requests
            // because it is possible to switch between months pretty quickly
            requestAbortController.current.abort();
        }

        setIsLoading(true);
        setInitialDate(date);
        setHighlightedDays([]);
        fetchHighlightedDays(date);
        setIsLoading(false);
    };

    

    return (
        <>
        <Grid container spacing={2}>
        
            <Grid xs={12} sm={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
                    <DateCalendar
                        defaultValue={defaultValue}
                        loading={isLoading}
                        onMonthChange={handleMonthChange}
                        renderLoading={() => <DayCalendarSkeleton />}
                        slots={{
                            day: ServerDay
                        }}
                        slotProps={{
                            day: {
                                highlightedDays,
                                holidays
                            } as any,
                        }}
                        onChange={(value: dayjs.Dayjs | null) => {handleDaySelect(value); onClickDay(value)}}
                        shouldDisableDate={shouldDisableDate}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid xs={12} sm={12} md={6}>
                <DayDetailBox
                    selectedValue={selectedValue}
                    open={open}
                    selectedEvents={selectedEvents}
                    timezone={timezone}
                />
            </Grid>
        </Grid>
        </>
    );
}
