import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import { useTheme } from '@mui/joy/styles';


// icons¡
import IconButton from '@mui/joy/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

interface ItemProps {
    children: React.ReactNode;
    onClick: () => void;
    onDelete: () => void;
}

export const Item = (props: ItemProps) => {
    const { children, onClick, onDelete } = props
    const theme = useTheme();
    return (
        <Sheet
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: theme.spacing(1),
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.radius.md,
            }}
        >{children}
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <IconButton color="neutral" onClick={onClick}>
                    <EditRoundedIcon />
                </IconButton>
                <IconButton color="danger" onClick={onDelete}>
                    <DeleteRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    );
};