//
//
//  Campaign
//
//

import {getCSRFToken} from "../utils";

import {toJsonOrRaise} from "../utils";

export default class Campaign {

    static async getCampaigns(getAccessToken: () => Promise<string>, client_id?: number | null, planification_id?: number | null ) {
        const token = await getAccessToken()
        let url = import.meta.env.VITE_API_URL + "/api/campaign/";
        let linkChar = '?';

        if (client_id !== null) {
            url += `${linkChar}client_id=${client_id}`;
            linkChar = '&';
        }

        if (planification_id !== null) {
            url += `${linkChar}planification_id=${planification_id}`;
            linkChar = '&';
        }
        
        return fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }
      
    static async createCampaign(
            getAccessToken: () => Promise<string>,
            name?: string,
            planification_id?: number,
            start_date?: string,
            timezone?: string,
            file?: File
    ) {
        let campaign_id: number | undefined = undefined;
        const token = await getAccessToken()
        let ret = fetch(import.meta.env.VITE_API_URL + `/api/campaign/`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name,
                planification_id,
                start_date,
                timezone
            }),
        }).then((response) => {
            if (response.ok)
                return response.json();

            return response.json().then(err => { throw new Error(err.message) });
        })
        .then((data) => {
            campaign_id = data['id'];
            if (campaign_id != undefined && file != undefined){
                let formData = new FormData();
                formData.append('file', file);

                return fetch(import.meta.env.VITE_API_URL + '/api/campaign/'+campaign_id+'/upload-debtors', {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "X-CSRFToken": getCSRFToken()
                    },
                    body: formData,
                }).then((response) => {
                    if (response.ok){
                        return fetch(import.meta.env.VITE_API_URL + '/api/campaign/'+campaign_id+'/create-first-step', {
                            method: "POST",
                            credentials: "include",
                            headers: {
                                "Authorization": `Bearer ${token}`,
                                "X-CSRFToken": getCSRFToken()
                            }
                        }).then((response) => {
                            if (response.ok)
                                return new Response(JSON.stringify(data), {status: 200});
                
                            return response.json().then(err => { throw new Error(err.message) });
                        }).catch(error => {
                            console.error(error);
                            error["status"] = 400;
                            this.deleteCampaign(getAccessToken, campaign_id);
                            return error;
                        })
                    }

                    return response.json().then(err => { throw new Error(err.message) });
                })
            }
        })
        .catch(error => {
            console.error(error);
            error["status"] = 400;
            return error;
        })

        return ret;
    }

    static async getCampaignById(getAccessToken?:any, campaign_id?: number) {
        
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/campaign/${campaign_id}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        }).then(toJsonOrRaise)
    }

    static async editCampaign(getAccessToken: () => Promise<string>, campaign_id?: number, name?: string) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/campaign/${campaign_id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name: name
            })
        }).then(toJsonOrRaise)
    }

    static async deleteCampaign(getAccessToken: () => Promise<string>, campaign_id?: number) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL +  `/api/campaign/${campaign_id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        })
    }

    static async resolveConflictsCampaign(
        {   
           getAccessToken,
            campaignId,
            dateConflicts = []
        } : {
            getAccessToken: () => Promise<string>,
            campaignId: number,
            dateConflicts?: Array<Record<string, any>>
        }
    ) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + "/api/campaign/"+campaignId+"/conflicts", {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                "conflicts": dateConflicts
            }),
        }).then(toJsonOrRaise)
    }
}
