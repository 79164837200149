//
//
//  Planification
//
//

import {getCSRFToken} from "../utils";

import {toJsonOrRaise} from "../utils";

export default class Planification {

    static async getPlanifications(getAccessToken: () => Promise<string>, client_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/?client_id=${client_id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }
      

    static async createPlanification(getAccessToken: () => Promise<string>, name?: string, client_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name,
                ...(client_id !== 0 ? { client_id } : {}),
              }),
        }).then(toJsonOrRaise)
    }

    static async getPlanificationById(getAccessToken: () => Promise<string>, planification_id?: number) {
        
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/${planification_id}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        }).then(toJsonOrRaise)
    }

    static async editPlanification(getAccessToken: () => Promise<string>, planification_id?: number, name?: string) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/${planification_id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name: name
            })
        }).then(toJsonOrRaise)
    }

    static async deletePlanification(getAccessToken: () => Promise<string>, planification_id?: number, ) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/${planification_id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        })
    }
}
