
import { useTranslation } from "react-i18next";
import Box from '@mui/joy/Box';
import Breadcrumbs from '../../components/Header/Breadcrumbs';
import Typography from '@mui/joy/Typography';
import Carousel from "./Carousel";


export default function HomePage() {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Breadcrumbs elements={[
                    { "name": t("Home") },
                ]} />
            </Box >
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2"> {t("Home")} </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', }}>
                <Carousel/>
            </Box>
        </>
    );
}