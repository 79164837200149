import './App.css'
import {
  Route,
  useNavigate,
  Routes,
} from "react-router-dom";
import { RequireLoggedIn } from './components/RequireLoggedIn';
import CampaignCreate from './pages/CampaignCreate';
import CampaignList from './pages/CampaignList';
import CampaignDetail from './pages/CampaignDetail';
import MailTemplate from './pages/Templates/MailTemplate';
import Strategies from './pages/Strategies';
import StrategyEditor from './pages/StrategyEditor';
import PaymentStatus from './pages/PaymentStatus';
import TestSend from './pages/TestSend';
import MailEdit from './pages/Templates/MailEdit';
import SMSTemplate from './pages/Templates/SMSTemplate';
import SMSEdit from './pages/Templates/SMSEdit';
import Statistics from './pages/Statistics';


import Admin from './pages/Admin';
import { Auth0Provider } from "@auth0/auth0-react";

/* Allow Material and Joy together */
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import HomePage from './pages/Home/Home';
import AppLayout from './pages/Layout';
import { ClientProvider } from './context/ClientContext';
import React from 'react';
import { Client } from './interfaces';


const materialTheme = materialExtendTheme();
export default function App() {
  
  const navigate = useNavigate()
  const [client] = React.useState<Client | null>(null)

  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }


  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE
          }}
          onRedirectCallback={onRedirectCallback}
          cacheLocation="localstorage"
        >
          <ClientProvider
            client={client}
          >
          <Routes>
              <Route path='login/' element={<RequireLoggedIn />}/>
              <Route path='/' element={<AppLayout />}>
                <Route index element={<HomePage />} />
                <Route path="strategies/" element={<Strategies />} />
                <Route path="strategies/:id" element={<StrategyEditor />} />
                <Route path="mail/" element={<MailTemplate />} />
                <Route path="mail/:id" element={<MailEdit />} />
                <Route path="sms/" element={<SMSTemplate />} />
                <Route path="sms/:id" element={<SMSEdit />} />
                <Route path="campaign/" element={<CampaignList />} />
                <Route path="campaign/add/" element={<CampaignCreate />} />
                <Route path="campaign/:id/" element={<CampaignDetail />} />
                <Route path="test-send/" element={<TestSend />} />
                <Route path="payment-status" element={<PaymentStatus />} />
                <Route path="statistics/" element={<Statistics />} />
                <Route path="admin/" element={<Admin />} />
              </Route>
          </Routes>
          </ClientProvider>
        </Auth0Provider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  )
}

