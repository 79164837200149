//
//
//  Uploads
//
//

import {getCSRFToken} from "../utils";


export class RequestStatusFailure extends Error {

    response: any

    constructor(response: any) {
        super()
        this.response = response
    }

}

import {toJsonOrRaise} from "../utils";

export default class Uploads {

static async uploadImage(token: string, client_id?: number, formData?: FormData) {
    return fetch(import.meta.env.VITE_API_URL + `/api/image/upload?client_id=${client_id}`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
        },
        body: formData,
    }).then(toJsonOrRaise)}

}
