import { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import InputLabel from '@mui/material/InputLabel';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import Select from '@mui/joy/Select';
import FormHelperText from '@mui/joy/FormHelperText';
import { InfoOutlined } from '@mui/icons-material';


interface SelectorProps {
    url: string;
    label?: string | null;
    value_field?: string;
    text_field?: string;
    defaultSelected: string;
    onChange: (event: React.SyntheticEvent | null, newValue: string | null) => void;
    error?: boolean;
    helperText?: React.ReactNode;
    width?: number;
    getAccessToken: () => Promise<string>;
}

async function getData (getAccessToken: () => Promise<string>, url: string){

    const token = await getAccessToken();
    const requestOpts = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization':`Bearer ${token}`}
    }

    return await fetch(url, requestOpts)
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log(err.message);
        });

}

export default function PopulatedSelector({ 
    url,
    label = null,
    value_field="id",
    text_field="name",
    defaultSelected,
    onChange,
    error=false,
    helperText="",
    width=450,
    getAccessToken
}: SelectorProps) {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(defaultSelected);
    
    useEffect(() => {
        let ret = getData(getAccessToken, url);
        ret.then((data) => {
            setOptions(data);
        })

    }, [url]);

    const handleChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
        onChange(event, newValue);
        if (newValue) {
        setSelected(newValue as string)
    }
    };

    return (
        <Box sx={{ width: width}}>
            <FormControl error={error}>
                {label && <InputLabel>{label}</InputLabel>}

                <Select
                    id="select"
                    value={selected}
                    defaultValue={selected}
                    onChange={handleChange}
                    slotProps={{
                        listbox: {
                            placement: 'bottom-start',
                        },
                    }}
                >
                    {options.map((option) => {
                        return (
                            <Option value={option[value_field]}>{option[text_field]}</Option>
                        );
                    })}
                </Select>

                {error && <FormHelperText> <InfoOutlined /> {helperText} </FormHelperText>}
            </FormControl>
        </Box>
    );
}