import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ModalClose from "@mui/joy/ModalClose";

import LanguageIcon from '@mui/icons-material/Language';
import IconButton from "@mui/joy/IconButton";
import Grid from "@mui/joy/Grid";

export default function LanguageSwitcher () {
    const options: Record<string, string> = {
        "es": "Español",
        "en": "English"
    }

    const { i18n } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);
    const [open, setOpen] = React.useState(false);

    const handleSelect = (newValue: string) => {
        setSelectedLanguage(newValue);
        i18n.changeLanguage(newValue as string);
        setOpen(false);
    };
    
    return (
        <Box>

            <IconButton sx={{ width: '100%' }} variant="outlined" color="neutral" onClick={() => setOpen(true)}>
                <Grid container spacing={2}>
                    <Grid xs={3}>
                        <LanguageIcon />
                    </Grid>
                    <Grid xs={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography level="title-sm">{options[selectedLanguage]}</Typography>
                    </Grid>
                </Grid>
            </IconButton>

            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={open}
              onClose={() => setOpen(false)}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                    minWidth: 300,
                    maxWidth: 500,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                    }}
                >
                        <ModalClose variant="plain" sx={{ m: 1 }} />
                        
                        <List>
                            <ListItem><ListItemButton onClick={() => handleSelect("es")}>Español</ListItemButton></ListItem>
                            <ListItem><ListItemButton onClick={() => handleSelect("en")}>English</ListItemButton></ListItem>
                        </List>
                </Sheet>
            </Modal>
        </Box>
    );
}