
import AppLayout from "../pages/Layout";



export function RequireLoggedIn() {

    return (
        <AppLayout />
    );
}
