//
//
//  App Layout
//
//

import { Box, CssVarsProvider, Typography } from "@mui/joy";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import HomeSidebar from "./Home/HomeSidebar";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import { useClient } from "../context/ClientContext";
import { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Api from "../services/Api";
import React from 'react';
import Sheet from '@mui/joy/Sheet';
import { closeSidebar } from "../utils";
import { IconButton } from '@mui/joy';
import { Divider } from '@mui/joy';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';

//icons
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import { useNavigate } from 'react-router-dom';
import LanguageSwitcher from "../components/Sidebar/LanguageSwitcher";
import { MaiaLogoIcon } from "../components/Icons/MaiaLogoIcon";
import { Client } from "../interfaces";
import { useTranslation } from "react-i18next";

function AppLayout() {

    const {t} = useTranslation()
    const { client, setClient } = useClient()
    const [clients, setClients] = useState<{ id: number, name: string }[] | undefined>(undefined);
    const { isAuthenticated, getAccessTokenSilently, user, logout, isLoading } = useAuth0()
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [isLoadingClient, setIsLoadingClient] = useState(true);
    const [isLogingOut, setIsLogingOut] = useState(false);

    useEffect(() => {
        if (client === null) {
            fetchClients()
        }
    }, [isAuthenticated]);

    const fetchClients = async () => {
        const data = await Api.getClients(getAccessTokenSilently);
        const clientId = sessionStorage.getItem('clientId');
        setClients(data);
        const userRoles = user!['https://maia-debtcollection/api/roles'];
        user!.is_staff = Array.isArray(userRoles) && userRoles.includes('Staff');
        setIsLoadingClient(false)
        if (user!.is_staff === true) {
            if (clientId) {
                const foundDataItem = data.find((item: Client) => item.id === parseInt(clientId));
                setClient(foundDataItem);
            } else {
                setOpen(true);
            }
        } else {
            setClient(data[0]);
            sessionStorage.setItem("clientId", data[0].id.toString());
        }
    }


    const handleSelectClient = (client: Client) => {
        setClient(client)
        sessionStorage.setItem("clientId", client.id.toString());
        navigate('/')
        setOpen(false);
    };

    function onLogout() {
        setClient(null)
        setIsLogingOut(true)
        sessionStorage.removeItem("token")
        localStorage.removeItem("token")
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    const Component = withAuthenticationRequired(Outlet, {
        returnTo: () => window.location.hash.substring(1),
        onRedirecting: () => {
            return (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )
        }
    })
    if (isLogingOut) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLogingOut}
            >
                <CircularProgress color="inherit" />
            </Backdrop>)
    }
    if (!isAuthenticated) {
        return (
            <Component />
        )
    } else if (isLoadingClient) {
        return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>)
    }

    {
        return (
            <>
                <CssVarsProvider disableTransitionOnChange>
                    <CssBaseline />
                    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                        <Header />
                        <Box
                            className="SecondSidebar-overlay"

                            sx={{
                                position: 'fixed',
                                zIndex: 9998,
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                opacity: 'var(--SideNavigation-slideIn)',
                                backgroundColor: 'var(--joy-palette-background-backdrop)',
                                transition: 'opacity 0.4s',
                                transform: {
                                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                                    lg: 'translateX(-100%)',
                                },
                            }}
                            onClick={() => closeSidebar()}
                        />
                        <Sheet
                            className="SecondSidebar"
                            color="neutral"
                            sx={{
                                position: {
                                    xs: 'fixed',
                                    lg: 'fixed',
                                },
                                transform: {
                                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                                    lg: 'none',
                                },
                                transition: 'transform 0.4s',
                                zIndex: 9999,
                                height: '100dvh',
                                top: 0,
                                p: 2,
                                flexShrink: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                borderRight: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <IconButton variant="soft" color="primary" size="sm">
                                    <MaiaLogoIcon />
                                </IconButton>

                                <Typography level="title-lg">Debt Collection</Typography>
                            </Box>
                            {client && <HomeSidebar />}
                            <Divider />
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Box sx={{ minWidth: 0, flex: 1 }}>
                                    {user?.is_staff ? (
                                        <Button sx={{ width: '100%' }} variant="outlined" color="neutral" onClick={() => setOpen(true)}>
                                            <Typography level="title-sm">{client?.name}</Typography>
                                        </Button>

                                    ) : (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                            <Typography sx={{ width: '100%' }} level="title-sm">{client?.name}
                                            </Typography>
                                        </Box>)}

                                    <Modal
                                        aria-labelledby="modal-title"
                                        aria-describedby="modal-desc"
                                        open={open}
                                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <Sheet
                                            variant="outlined"
                                            sx={{
                                                minWidth: 300,
                                                maxWidth: 500,
                                                borderRadius: 'md',
                                                p: 3,
                                                boxShadow: 'lg',
                                            }}
                                        >
                                            <Typography
                                                component="h2"
                                                id="modal-title"
                                                level="h4"
                                                textColor="inherit"
                                                fontWeight="lg"
                                                mb={1}
                                            >
                                                {t("Selecciona un cliente")}
                                            </Typography>

                                            <List>
                                                {clients?.map((client) => (
                                                    <ListItem>
                                                        <ListItemButton onClick={() => handleSelectClient(client)}>{client.name}</ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Sheet>
                                    </Modal>
                                </Box>
                            </Box>

                            <LanguageSwitcher />

                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <AccountCircleRoundedIcon />
                                <Box sx={{ minWidth: 0, flex: 1 }}>
                                    <Typography level="title-sm">{user?.username}</Typography>
                                    <Typography
                                        level="body-xs"
                                        noWrap
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '120px'
                                        }}
                                    >
                                        {user?.email}
                                    </Typography>
                                </Box>
                                <IconButton onClick={() => onLogout()} size="sm" variant="plain" color="neutral">
                                    <LogoutRoundedIcon />
                                </IconButton>
                            </Box>
                        </Sheet>
                        <Box
                            component="main"
                            className="MainContent"
                            sx={{
                                marginLeft: { md: 25, lg: 25 },
                                px: {
                                    xs: 2,
                                    md: 6,
                                },
                                pt: {
                                    xs: 'calc(12px + var(--Header-height))',
                                    sm: 'calc(12px + var(--Header-height))',
                                    md: 3,
                                },
                                pb: {
                                    xs: 2,
                                    sm: 2,
                                    md: 3,
                                },
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                height: '100dvh',
                                gap: 1,
                            }}
                        >
                            {client && <Outlet />}
                        </Box>
                    </Box >
                </CssVarsProvider >
            </>
        )
    }
}

export default AppLayout

