import Avatar from '@mui/joy/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneIcon from '@mui/icons-material/Phone';


export default function EventAvatar({ actionType="" }) {

    if (actionType == "Mail") {
        return (
            <Avatar sx={{ bgcolor: '#bbdefb', color: '#1e88e5' }}>
                <EmailIcon />
            </Avatar>
        );
    } else if (actionType == "Sms"){
        return (
            <Avatar sx={{ bgcolor: '#ffe0b2', color: '#fb8c00' }}>
                <SmsIcon />
            </Avatar>
        );
    } else if (actionType == "Call"){
        return (
            <Avatar sx={{ bgcolor: '#c8e6c9', color: '#43a047' }}>
                <PhoneIcon />
            </Avatar>
        );
    }
}
