import {createContext, ReactNode, useContext, useState} from "react"
import {Client} from "../interfaces.ts";

interface ClientContext {
    client: Client | null
    setClient: (client: Client | null) => void
}

const ClientContext = createContext<ClientContext>({
    client: null,
    setClient: () => {}
})

export const ClientProvider = function({client, children}: {client: Client | null, children: ReactNode}) {
    const [currentClient, setCurrentClient] = useState(client)

    return (
        <ClientContext.Provider value={{ client: currentClient, setClient: setCurrentClient }}>
            {children}
        </ClientContext.Provider>
    )
}

export function useClient() {
    return useContext(ClientContext)
}