import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import Skeleton from '@mui/joy/Skeleton';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import PlanificationEvents from '../../../services/Drawflow';
import { formatSendTime } from '../../../utils';
import { useAuth0 } from '@auth0/auth0-react';



type MenuTimeDelayProps = {
    planStepId: number | undefined;
    defaultTimeForm: { days: number, hours: number, minutes: number };
    onClose?: () => void;
    onDeleted?: () => void;

};
export default function MenuStart(props: MenuTimeDelayProps) {
    const { t } = useTranslation()
    const { getAccessTokenSilently } = useAuth0()
    const [loadingSave, setLoadingSave] = useState(false)
    const { planStepId, defaultTimeForm, onClose } = props
    const [timeForm, setTimeForm] = useState<{ days: number, hours: number, minutes: number }>(defaultTimeForm)
    const timePickerValue = dayjs().hour(timeForm.hours).minute(timeForm.minutes);

    useEffect(() => {
        setTimeForm(defaultTimeForm);

    }, [planStepId, defaultTimeForm]);


    const saveItem = async () => {
        const sendTime = formatSendTime(timeForm)
        setLoadingSave(true)
        await PlanificationEvents.editPlanStep(getAccessTokenSilently, planStepId!, {timedelta: sendTime, step_type: 'delay' })
        setLoadingSave(false)
        onClose!()
    }

    return (
        <>
            <DialogTitle>{t("Configuración")}</DialogTitle>
            <ModalClose />
            <Divider />
            <DialogContent sx={{ gap: 2 }}>
                <FormLabel sx={{ typography: 'title-md' }}>{t("Seleccione una hora")}:</FormLabel>
                <FormControl orientation="horizontal">
                    <Box sx={{ flex: 1, pr: 1 }}>
                        {timeForm ? (
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={timePickerValue}
                                        onChange={(newValue) => {
                                            setTimeForm({
                                                ...timeForm,
                                                hours: newValue!.hour(),
                                                minutes: newValue!.minute()
                                            });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        ) : (
                            <Skeleton variant="rectangular" width={343} height="1em" />
                        )}


                    </Box>

                </FormControl>
            </DialogContent >
            <Divider sx={{ mt: 'auto' }} />
            <Stack
                direction="row"
                justifyContent="space-between"
                useFlexGap
                spacing={1}
            >
                <Button onClick={() => saveItem()} loading={loadingSave} variant="solid">{t("Guardar")}</Button>
            </Stack>
        </>
    )
}
