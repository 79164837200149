import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';

import BaseView from './BaseView';
import VerticalLinearStepper from '../components/CampaignStepper';
import Breadcrumbs from '../components/Header/Breadcrumbs'

export default function Campaign() {
    const { t } = useTranslation();

    return (
    <BaseView view="/campaign/add/">
        <Breadcrumbs elements={[
            {"name": t("Campañas"), "href": "/campaign/"},
            {"name": t("Nueva campaña")}
        ]}/>
        
        <Box sx={{ width: '100%' }}>
            <center><VerticalLinearStepper></VerticalLinearStepper></center>
        </Box>
    </BaseView>
    );
}