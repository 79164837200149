//
//
//  Drawflow
//
//

import {getCSRFToken} from "../utils";

import {toJsonOrRaise} from "../utils";

export default class PlanificationEvents {

    static async getContentType(getAccessToken: () => Promise<string>, model_name?: string) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/content_type/${model_name}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }

    static async getPlanSteps(getAccessToken: () => Promise<string>, planification_id?: number,) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/${planification_id}/steps`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }

    static async getPlanTransitions( getAccessToken: () => Promise<string> , planification_id?: number) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/${planification_id}/transitions`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }
      

    static async createPlanStep({token, planification_id, step_type, action_id, content_type_id, timedelta, operation, target, field, value, timedeltas, n_attempts}: {token: string, planification_id?: number, step_type?: string, action_id?: number, content_type_id?: number, timedelta?: string, operation?: string, target?: string, field?: string, value?: string,  timedeltas?: string[], n_attempts?: number}) {

        return fetch(import.meta.env.VITE_API_URL + `/api/planification_step/`, {

            method: "POST",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                planification_id: planification_id,
                step_type:step_type,
                action_id: action_id,
                content_type_id: content_type_id,
                timedelta: timedelta,
                operation: operation,
                target: target,
                field: field,
                value: value,
                 timedeltas:  timedeltas,
                n_attempts: n_attempts
              }),
        }).then(toJsonOrRaise)
    }

    static async getPlanStepById(getAccessToken: () => Promise<string>, planification_step_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification_step/${planification_step_id}`, {
            
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        }).then(toJsonOrRaise)
    }

    static async editPlanStep(getAccessToken: () => Promise<string>, planification_step_id: number, options: {action_id?: number, content_type_id?: number, step_type?: string, timedelta?: string, operation?: string, target?: string, field?: string, value?: string,  timedeltas?:string[], n_attempts?: number}) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification_step/${planification_step_id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                action_id: options.action_id,
                content_type_id: options.content_type_id,
                step_type: options.step_type,
                timedelta: options.timedelta,
                operation: options.operation,
                target: options.target,
                field: options.field,
                value: options.value,
                timedeltas:  options.timedeltas,
                n_attempts: options.n_attempts
            })
        }).then(toJsonOrRaise)
    }

    static async connectPlanStep({getAccessToken, planification_id, src_id, dst_id, branch}: {getAccessToken: any, planification_id?: number, src_id?: number,  dst_id?: number, branch?: boolean}) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification/${planification_id}/connect/`, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                src_id: src_id,
                dst_id: dst_id,
                branch: branch 
                
            })
        }).then(toJsonOrRaise)
    }

    static async deletePlanStep(getAccessToken: () => Promise<string>, planification_step_id?: number, branch?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/planification_step/${planification_step_id}?branch=${branch}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        })
    }
}
