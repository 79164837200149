import {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';


export interface DeleteModalProps {
    isOpen: boolean;
    content: string
    onClose: () => void;
}



export default function InfoModal(props: DeleteModalProps) {
    const { t } = useTranslation();

    const { isOpen, content, onClose } = props;
    const [open, setOpen] = useState(isOpen);
    

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen]);


    return (
        <Modal open={open} onClose={() => onClose()}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <InfoRoundedIcon />
                    {t("Información")}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {content}
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
}