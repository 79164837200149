import * as React from 'react';
import { useTheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import { useTranslation } from "react-i18next";
import MobileStepper from '@mui/material/MobileStepper';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
//@ts-ignore
import SwipeableViews from 'react-swipeable-views';

// icons
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';


export default function Carousel() {

    const { t } = useTranslation();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);


    const images = [
        {
            label: t('Pasos para automatizar la recolección de deuda'),
        },
        {
            label: t('1. Crea tus plantillas de Mail y SMS'),
            text: t('En el menú Plantillas, diseña los correos electrónicos y mensajes de texto que enviarás a los deudores.'),
            imgPath:
                `${import.meta.env.VITE_STATIC_PATH}/img/mail-tutorial.png`,
        },
        {
            label: t('2. Diseña tu estrategia personalizada.'),
            text: t('En Estrategias, define el flujo de recolección de deuda: elige plantillas y configura intervalos entre mensajes.'),
            imgPath:
                `${import.meta.env.VITE_STATIC_PATH}/img/strategy-tutorial.png`,
        },
        {
            label: t('3. Ejecuta la campaña.'),
            text: t('En el menú Campañas, crea una campaña seleccionando una estrategia predefinida, estableciendo una fecha de inicio e importando los contactos para la recolección de deuda.'),
            imgPath:
                `${import.meta.env.VITE_STATIC_PATH}/img/campaign-tutorial.png`
        },
    ];

    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
            <Sheet
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 2,
                    background: 'white'
                }}
            >
                <Typography level='h2'>{images[activeStep].label}</Typography>
            </Sheet>
            <SwipeableViews


                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={step.label}>
                        {index === 0 ? (
                            <Box sx={{ display: 'flex', m: 4, flexDirection: 'row' }}>
                                <Box
                                    sx={{
                                        p: 4,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: theme.radius,
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: '100%',
                                        height: 470,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                     <Box sx={{ display: 'flex', p:4,  justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
                                    <Box sx={{display:'flex', flexDirection:'column', height: '100%', justifyContent: 'center', alignItems: 'center'  }}>
                                        <Typography  sx={{ m: 2}} level="h1"> <RateReviewRoundedIcon fontSize="large" color='action'/> <Typography color='primary'>{t('1. ')}</Typography>{t('Crea tus plantillas de Mail y SMS.')}</Typography>
                                        <Typography  sx={{ m: 2}} level="h1"> <RouteRoundedIcon fontSize="large" color='action'/><Typography color='primary'>{t('2. ')}</Typography>{t('Diseña tu estrategia personalizada.')}</Typography>
                                        <Typography  sx={{ m: 2}} level="h1"> <CampaignRoundedIcon fontSize="large" color='action'/><Typography color='primary'>{t('3. ')}</Typography>{t('Ejecuta la campaña.')}</Typography>
                                    </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            Math.abs(activeStep - index) <= 2 ? (
                                <Box sx={{ display: 'flex', m: 4, flexDirection: 'row' }}>
                                    <Box
                                        component="img"
                                        sx={{
                                            p: 4,
                                            border: '1px solid',
                                            borderColor: 'divider',
                                            borderRadius: theme.radius,
                                            display: 'block',
                                            overflow: 'hidden',
                                            width: 900,
                                            height: 470,
                                        }}
                                        src={step.imgPath}
                                        alt={step.label}
                                    />
                                    <Box
                                        sx={{ display: 'flex', p: 4, justifyContent: 'center', width: '100%', alignItems: 'center' }}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '50%', alignItems: 'center' }}>
                                            <Typography level='h2'>{images[activeStep].text}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : null)}
                    </div>
                ))}
            </SwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        
                        <KeyboardArrowRight />

                    </Button>
                }
                backButton={
                    <Button onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        
                    </Button>
                }
            />
        </Box>
    );
}
