import { useCallback, useEffect, useMemo, useRef } from "react";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css"
import Uploads  from "../../services/Uploads"
import { useAuth0 } from "@auth0/auth0-react";
import ReactQuill from "react-quill";

interface RichTextEditorProps {
  value: string;
  onChange: (newValue: string) => void;
  onInsertText?: string
}

function RichTextEditor(props: RichTextEditorProps) {

  
  const { value, onChange, onInsertText } = props
  const {getAccessTokenSilently} = useAuth0()
  const quillRef = useRef<ReactQuill | null>(null); 


  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
        authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        },
    });

    return accessToken
  }

  useEffect(() => {
    if (onInsertText && quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      quill.insertText(range.index, onInsertText);
      onChange(quill.root.innerHTML);
    }
  }, [onInsertText]);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // When a file is selected
    input.onchange = async () => {
      if (!input.files || !quillRef) {
        return;
      }
      const file = input.files[0];
      const formData = new FormData();
      formData.append('file', file);
      Uploads.uploadImage(await getAccessToken(), sessionStorage['clientId'], formData).then((result) => {
        const imageUrl = result.image_url
        const quillEditor = quillRef.current!.getEditor()
        const range = quillEditor.getSelection(true);
        quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
      })
      const reader = new FileReader();
      //upload to bucket

      reader.readAsDataURL(file);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean",
  ];

  return (
    quillRef && (
      <QuillEditor
        ref={(el) => (quillRef.current = el)}
        theme="snow"
        value={value}
        formats={formats}
        modules={modules}
        onChange={onChange}
      />
  ))
};

export default RichTextEditor;