import React from "react";

import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";

import BaseView from './BaseView';
import {getCSRFToken, getAccessToken} from "../utils";
import { useTranslation } from "react-i18next";


export default function TestSend() {
    const [receiver, setReceiver] = React.useState("");
    const {t} = useTranslation()

    function startCall() {
        const token = getAccessToken()
        let aux = receiver.replace("+", "%2B")
        let url = import.meta.env.VITE_API_URL + '/api/test/call?phone='+aux

        const options = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        fetch(url, options)
            .then((response) => response.json())
            .catch((err) => {
                console.log(err.message);
            });
    }

    function sendSms() {
        const token = getAccessToken()
        let aux = receiver.replace("+", "%2B")
        let url = import.meta.env.VITE_API_URL + '/api/test/sms?phone='+aux

        const options = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        fetch(url, options)
            .then((response) => response.json())
            .catch((err) => {
                console.log(err.message);
            });
    }

    function sendEmail() {
        const token = getAccessToken()
        let url = import.meta.env.VITE_API_URL + '/api/test/email'

        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"receiver_email": receiver})
        }

        fetch(url, options)
            .then((response) => response.json())
            .catch((err) => {
                console.log(err.message);
            });
    }
    
    return (
    <BaseView view="/test-send/">
        <Input 
            id="receiver-input"
            placeholder={t("Receptor")}
            variant="outlined"
            onChange={(v) => setReceiver(v.target.value)}
        />
        <Button 
            onClick={startCall}
        >
                {t("Llamada")}
        </Button>
        <Button 
            onClick={sendSms}
        >
                SMS
        </Button>
        <Button 
            onClick={sendEmail}
        >
                Email
        </Button>
    </BaseView>
    );
}