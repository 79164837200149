import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumbs from '../components/Header/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

// icons
import AddIcon from '@mui/icons-material/Add';

import Campaign from '../services/Campaign';
import ListItems from '../components/ItemList/ItemList';
import BaseView from './BaseView';
import { useClient } from '../context/ClientContext';
import InfoModal from '../components/ActionModals/InfoModal';
import { IconButton } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {useAuth0} from "@auth0/auth0-react";
import { getClientId } from '../utils';


export default function Campaigns() {

    const { getAccessTokenSilently } = useAuth0()
    const { t } = useTranslation();
    const client = useClient()
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState<{ id: number, name: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    
    React.useEffect(() => {
        loading
        setLoading(true);

        const fetchData = async () => {
            const client_id  = await getClientId()
            Campaign.getCampaigns(getAccessTokenSilently, null, client_id)
                .then(data => {
                    setCampaigns(data)
                }).catch(err => {
                    console.error(err)
                }).finally(() => {
                    setLoading(false);
                })

        }
    fetchData()
    }, [client]);

    const selectCampaign = (id: number) => {
        const url = `/campaign/${id}`;
        navigate(url);
    };


    /*const saveCampaign = async (id: number, editedRow: string) => {
        await Campaign.editCampaign({ campaign_id: id, name: editedRow })

    };*/

    const deleteCampaign = async (id: number) => {
        return await Campaign.deleteCampaign(getAccessTokenSilently, id)
    };

    return (
    <BaseView view="/campaign/">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width:'100%' }}>
                <Breadcrumbs elements={[
                    { "name": t("Campañas") },
                ]} />
                <IconButton aria-label="settings" onClick={()=>setInfoOpen(true)}>
                    <InfoRoundedIcon />
                </IconButton>
            </Box >
        <Box
            sx={{
                display: 'flex',
                my: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            <Typography level="h2">{t("Campañas")}</Typography>
            <Button
                onClick={() => navigate('add/')}
                component={Link}
                color="primary"
                startDecorator={<AddIcon />}
                size="sm"
            >
                {t("Nueva campaña")}
            </Button>
        </Box>
        <Box sx={{
            display: 'flex',
            my: 1,
            gap: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
        }}>
            <ListItems
                items={campaigns}
                onSelect={selectCampaign}
                onDelete={deleteCampaign}
            />
        </Box>
        <InfoModal
            isOpen={infoOpen}
            content={t('En la página de Creación de Campañas de nuestra aplicación se efectúa el lanzamiento de iniciativas personalizadas de recolección de deudas. Comienza definiendo la fecha y zona horaria, y seleccionando una estrategia de comunicación previamente diseñada. Es esencial importar un archivo Excel con los datos de los deudores para que la campaña se ejecute correctamente.')}
            onClose={()=>setInfoOpen(false)}
            />
    </BaseView>
    );
}
