import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/es';

import Box from '@mui/joy/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import { InfoOutlined } from '@mui/icons-material';
import FormHelperText from '@mui/joy/FormHelperText';
import Grid from '@mui/joy/Grid';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import PopulatedSelector from './PopulatedSelector';
import UploadFile from './UploadFile';
import Campaign from '../services/Campaign';
import CampaignCalendar from './CampaignCalendar';
import { Data } from './CollapsibleTable';
import BasicAlert from './Alert';
import { useClient } from '../context/ClientContext';
import { useAuth0 } from '@auth0/auth0-react';


const STEP_PLAN = 0
const STEP_NAME = 1
const STEP_TIMEZONE = 2
const STEP_DATE = 3
const STEP_FILE = 4


interface CampaignDatePickerProps {
    changeHandler: (value: Dayjs | null) => void
    defaultValue: string
    error?: boolean
    holidays: Array<Record<string, any>>
}

function CampaignDatePicker(props: CampaignDatePickerProps) {
    const { t } = useTranslation();

    const { changeHandler, defaultValue, error=false, holidays } = props;


    const datePickerChangeHandler = (value: Dayjs | null) => {
        if (value !== null) {
            changeHandler(value);
        }
    };

    const isWeekend = (date: Dayjs) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const isHoliday = (date: Dayjs) => {
        var auxDate = date.toDate();
    
        for (let i = 0; i < holidays.length; i++) {
            if (holidays[i]["start"].toLocaleDateString() === auxDate.toLocaleDateString()) {
                return true;
            }
        }
      
        return false;
    };

    const shouldDisable = (date: Dayjs) => {
        return isHoliday(date) || isWeekend(date);
    };

    return (    
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker 
                onChange={datePickerChangeHandler}
                defaultValue={dayjs(defaultValue)}
                shouldDisableDate={shouldDisable}
                sx={{minWidth: 450}}
                disablePast
                slotProps={{
                    textField: {
                        error: error,
                        helperText: error && t("Seleccione una fecha de comienzo válida")
                    },
                }}
            />
        </LocalizationProvider>
    );
}

export default function VerticalLinearStepper() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = React.useState(0);
    const { getAccessTokenSilently } = useAuth0()
    const client = useClient()
    const [nameCampaign, setNameCampaign] = React.useState('');
    const [timezoneCampaign, setTimezoneCampaign] = React.useState('');
    const [dateCampaign, setDateCampaign] = React.useState('');
    const [planificationCampaign, setPlanificationCampaign] = React.useState(0);
    const [fileCampaign, setFileCampaign] = React.useState<File | undefined>(undefined);

    const [previewCampaign, setPreviewCampaign] = React.useState<Array<Record<string, any>>>([]);
    const [holidaysCalendar, setHolidaysCalendarCampaign] = React.useState<Array<Record<string, any>>>([]);

    const [successAlertStatus, setSuccessAlertStatus] = React.useState(false);
    const [errorAlertStatus, setErrorAlertStatus] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isNameInvalid, setIsNameInvalid] = React.useState(false);
    const [isPlanificationInvalid, setIsPlanificationInvalid] = React.useState(false);
    const [isDateInvalid, setIsDateInvalid] = React.useState(false);
    const [isTimezoneInvalid, setIsTimezoneInvalid] = React.useState(false);
    const [isFileInvalid, setIsFileInvalid] = React.useState(false);

    let auxDateConflicts: Array<Record<string, any>> = [];

    function nameChange(event: React.ChangeEvent<HTMLInputElement>){
        if (event.target.value === "") {
            setIsNameInvalid(true);
        } else {
            setNameCampaign(event.target.value);
            setIsNameInvalid(false);
            setErrorAlertStatus(false);
        }
    }

    async function timezoneChange(event: React.SyntheticEvent | null, newValue: string | null){
        if (event == null) return;

        if (newValue != null){
            setTimezoneCampaign(newValue);
            setIsTimezoneInvalid(false);
            setDateCampaign('');

            const token = await getAccessTokenSilently()
            let url = import.meta.env.VITE_API_URL + '/api/holiday/?country=ES'

            const options = {
                method: 'GET',
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }

            fetch(url, options)
                .then((response) => response.json())
                .then((data) => {
                    {data.map(function (row: Record<string, any>) {
                        row.start = new Date(row.start);
                        row.end = new Date(row.end)
                    })}
                    setHolidaysCalendarCampaign(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } else {
            setIsTimezoneInvalid(true);
        }
    }

    function convertDates(row: Record<keyof Data, any>, index: number) {
        row.timestamp = new Date(row.timestamp);
        let day = row.timestamp.getDay();
        let conflict = false;
        let conflictReason = "";

        for (let i = 0; i < holidaysCalendar.length; i++) {
            if (holidaysCalendar[i]["start"] <= row.timestamp && row.timestamp < holidaysCalendar[i]["end"]) {
                conflict = true;
                conflictReason = t(holidaysCalendar[i]["name"]);
                break;
            }
        }
        
        if (conflict == false) {
            if (day === 6) {
                conflict = true;
                conflictReason = t("Sábado");
            } else if (day === 0) {
                conflict = true;
                conflictReason = t("Domingo");
            }
        }

        if (conflict == true) {
            auxDateConflicts.push({
                "position": index,
                "id": row.id,
                "name": row.action_name,
                "type": row.action_type,
                "date": row.timestamp,
                "origDate": new Date(row.timestamp),
                "reason": conflictReason
            })
        }
    }

    async function getCampaignPreview(dateStr: string) {
        const token = await getAccessTokenSilently()
        let timezoneAux = timezoneCampaign.replace("+", "%2B")
        let url = import.meta.env.VITE_API_URL + '/api/planification/'+planificationCampaign+'/preview?start_date='+dateStr+"&timezone="+timezoneAux

        const options = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                {data.map(convertDates)}
                setPreviewCampaign(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }


    function dateChange(value: dayjs.Dayjs | null){
        if (value != null){
            let dateStr = value.format("YYYY-MM-DD");
            setDateCampaign(dateStr);
            setIsDateInvalid(false);

            getCampaignPreview(dateStr);
        } else {
            setIsDateInvalid(true);
        }
    }

    function planificationChange(event: React.SyntheticEvent | null, newValue: string | null) {
        if (event == null) return;

        if (newValue != null){
            setPlanificationCampaign(parseInt(newValue));
            setIsPlanificationInvalid(false);
            setDateCampaign('');

            setIsNameInvalid(false);
            setErrorAlertStatus(false);
        } else {
            setIsPlanificationInvalid(true);
        }
    }

    const uploadFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            setFileCampaign(files[0]);
            setIsFileInvalid(false);
        }
    };

    const handleNext = async (position: number) => {
        if (position === STEP_NAME) {
            if (nameCampaign === "") {
                setIsNameInvalid(true);
                return;
            } else {
                Campaign.getCampaigns(
                    getAccessTokenSilently, client.client?.id, planificationCampaign
                    ).then((response) => {
                    response.map((campaign: Record<string, any>) => {
                        if (campaign.name == nameCampaign) {
                            const errMsg = "Ya existe una campaña con ese nombre para la estrategia seleccionada";
                            setErrorMessage(errMsg);
                            setErrorAlertStatus(true);
                            setActiveStep(STEP_NAME);
                            setIsNameInvalid(true);
                            return;
                        }
                    })
                })
            }
        } else if (position === STEP_PLAN) {
            if (planificationCampaign === 0) {
                setIsPlanificationInvalid(true);
                return;
            }
        } else if (position === STEP_TIMEZONE) {
            if (timezoneCampaign === "") {
                setIsTimezoneInvalid(true);
                return;
            }
        } else if (position === STEP_DATE) {
            if (dateCampaign === "") {
                setIsDateInvalid(true);
                return;
            }
        } else if (position === STEP_FILE) {
            if (fileCampaign === undefined) {
                setIsFileInvalid(true);
                return;
            }

            let ret = await Campaign.createCampaign(
                getAccessTokenSilently,
                nameCampaign,
                planificationCampaign,
                dateCampaign,
                timezoneCampaign,
                fileCampaign
            );

            if (!ret.ok) {
                let errMsg = ret.message;
                if (ret.message.includes("UNIQUE")) {
                    errMsg = "Ya existe una campaña con ese nombre para la estrategia seleccionada";
                    setActiveStep(STEP_NAME);
                    setIsNameInvalid(true);
                } if (ret.message.includes("FILE")) {
                    errMsg = ret.message.replace("FILE ", "");
                    setActiveStep(STEP_FILE);
                    setIsFileInvalid(true);
                }

                setErrorMessage(errMsg);
                setErrorAlertStatus(true);
                return;
            } else {
                let data = await ret.json();
                let campaignId = data["id"];

                setSuccessAlertStatus(true);
                return navigate("/campaign/"+campaignId);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function isFailedStep(index: number) {
        if (index == STEP_NAME) {
            return isNameInvalid;
        } else if (index == STEP_PLAN) {
            return isPlanificationInvalid;
        } else if (index == STEP_TIMEZONE) {
            return isTimezoneInvalid;
        } else if (index == STEP_DATE) {
            return isDateInvalid;
        } else if (index == STEP_FILE) {
            return isFileInvalid;
        }

        return false;
    }


    const steps = [
        {
            label: t("Seleccione una estrategia"),
            content:<PopulatedSelector 
                url={import.meta.env.VITE_API_URL + `/api/planification/?client_id=${client.client!.id}`}
                text_field="name"
                onChange={planificationChange}
                defaultSelected={(planificationCampaign > 0) ? planificationCampaign as unknown as string : ""}
                error={isPlanificationInvalid}
                helperText={isPlanificationInvalid && t("Estrategia necesaria")}
                getAccessToken={getAccessTokenSilently}
            />
        },
        {
            label: t("Introduzca el nombre de la campaña"),
            content: 
                <>
                <FormControl error={isNameInvalid} style={{minWidth: 450}}>
                    <Input 
                        id="outlined-basic" 
                        placeholder={t("Nombre de la campaña")} 
                        variant="outlined" 
                        onChange={nameChange}
                        defaultValue={nameCampaign}
                        error={isNameInvalid}
                    />
                    {isNameInvalid && <FormHelperText><InfoOutlined /> {t("Nombre necesario")} </FormHelperText>}
                </FormControl>
                </>,
        },
        {
            label: t("Seleccione una zona horaria"),
            content:<PopulatedSelector 
                url={import.meta.env.VITE_API_URL + "/api/campaign/timezones/"}
                value_field="name"
                text_field="verbose_name"
                onChange={timezoneChange}
                defaultSelected={timezoneCampaign}
                error={isTimezoneInvalid}
                helperText={isTimezoneInvalid && t("Zona horaria necesaria")}
                getAccessToken={getAccessTokenSilently}
            />,
        },
        {
            label: t("Seleccione una fecha de comienzo"),
            content:
            <Grid container spacing={dateCampaign ? 6 : 0}>
                <Grid xs={12} sx={{display: "flex"}}>
                    <CampaignDatePicker 
                        changeHandler={dateChange}
                        defaultValue={dateCampaign}
                        error={isDateInvalid}
                        holidays={holidaysCalendar}
                    />
                </Grid>
                <Grid xs={12}>
                    {dateCampaign && 
                        <CampaignCalendar 
                            events={previewCampaign}
                            holidays={holidaysCalendar}
                            timezone={timezoneCampaign}
                        />
                    }
                </Grid>
                {/*{dateCampaign &&
                    <Grid xs={12} sx={{ display: "flex" }}>
                        <EventsLegend actionTypes={["Mail", "Sms", "Call"]}/>
                    </Grid>
                }*/}
            </Grid>
        },
        {
            label: t("Suba el fichero con la información de los deudores"),
            content: <UploadFile
                changeHandler={uploadFileChange}
                selectedFile={fileCampaign?.name}
                error={isFileInvalid}
            />
        },
    ];

    return (
        <>
        <Box sx={{ width: '100%' }}>
        <BasicAlert open={successAlertStatus} text={t("La campaña se ha creado correctamente")} severity="success" onCloseAlert={() => { setSuccessAlertStatus(false) }} />
        <BasicAlert open={errorAlertStatus} text={t(errorMessage)} severity="error" onCloseAlert={() => { setErrorAlertStatus(false) }} />

        <Stepper activeStep={activeStep} orientation='vertical'>
            {steps.map((step, index) => (
            <Step key={step.label}>
                <StepLabel error={isFailedStep(index)}>
                    {step.label}
                </StepLabel>
                <StepContent sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography>{step.content}</Typography>
                    <Box sx={{ mb: 2, display: "flex" }}>
                        <div>
                        <Button
                            onClick={() => handleNext(index)}

                            sx={{ mt: 1, mr: 1 }}
                        >
                            {index === steps.length - 1 ? t("Confirmar") : t("Continuar")}
                        </Button>
                        <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {t("Atrás")}
                        </Button>
                        </div>
                    </Box>
                </StepContent>
            </Step>
            ))}
        </Stepper>
        {activeStep === steps.length && (
            <Sheet sx={{ p: 3 }}>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                {t("Reiniciar")}
            </Button>
            </Sheet>
        )}
        </Box>
        </>
    );
}