import * as React from 'react';
import {useAuth0} from "@auth0/auth0-react";


interface PermissionsGateProps {
    target: string
    operation: string
}

const admin_views = [
    '/admin/'
]

const staff_views = [
    '/payment-status/',
    '/test-send/',
    '/statistics/',
]

export default function PermissionsGate(props: React.PropsWithChildren<PermissionsGateProps>) {
    const {  user } = useAuth0()
    const [hasPermission, setHasPermission] = React.useState(false);


    React.useEffect(() => {
        if (admin_views.includes(props.target)) {
            if (user!.is_superuser) {
                setHasPermission(true)
            }
            setHasPermission(false)
        } else if (user!.is_staff) {
            setHasPermission(true)
        } else if (staff_views.includes(props.target)) {
                setHasPermission(false)
        } else {
            setHasPermission(true)
        }


    }, [props.target, props.operation]);

    

    return (
        <>
        {hasPermission && props.children}
        </>
    )
}