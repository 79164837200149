import { ReactNode } from 'react';

import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';

interface DataCardProps {
    children: ReactNode, 
    title: string, 
    content: string,
    width?: number,
    height?: number
}


export default function DataCard(props: DataCardProps) {
    const {children, title, content, width=300, height=100} = props;
    return (
        <Sheet
            variant="outlined"
            sx={{ 
                width: {width}, 
                height: {height},
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
            }}
        >
            <Grid sx={{
                display: "flex",
                alignItems: "center"
            }}>
                <Grid xs={4} sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    {children}
                </Grid>
                <Grid xs={8} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <Grid xs={12}>
                        <Typography level="h4" gutterBottom>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography gutterBottom>
                            {content}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Sheet>
    )
}