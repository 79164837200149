import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Planification from '../services/Planification';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Breadcrumbs from '../components/Header/Breadcrumbs';
import Typography from '@mui/joy/Typography';
import CreateModal from '../components/ActionModals/CreateModal';
import { IconButton, Skeleton } from '@mui/joy';

// icons
import AddIcon from '@mui/icons-material/Add';

import BaseView from './BaseView';
import ListItems from '../components/ItemList/ItemList';
import PlanificationEvents from '../services/Drawflow';
import { useClient } from '../context/ClientContext';
import InfoModal from '../components/ActionModals/InfoModal';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { getClientId } from '../utils';
import { useAuth0 } from '@auth0/auth0-react';


export default function Strategies() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const client = useClient()
    const { getAccessTokenSilently } = useAuth0()
    const [strategies, setStrategies] = useState<{ id: number, name: string }[]>([]);
    const [loading, setLoading] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [errorOnCreate, setErrorOnCreate] = useState('')


    React.useEffect(() => {
        setLoading(true)
        
        const fetchData = async () => {
            const client_id = await getClientId()
            Planification.getPlanifications(getAccessTokenSilently, client_id )
                .then(data => {
                    setStrategies(data)
                }).catch(err => {
                    console.error(err)
                }).finally(() => {
                    setLoading(false)
                })

        }
        fetchData()
    }, [client]);


    const openNewFormDialog = () => {
        setOpenDialog(true)
    }

    const closenNewFormDialog = () => {
        setOpenDialog(false)
    }

    const newStrategy = async (name: string) => {
        const client_id = await getClientId()
        const token = await getAccessTokenSilently()
        await Planification.createPlanification(getAccessTokenSilently, name, client_id)
            .then(data => {
                const planification_id = data.id
                PlanificationEvents.createPlanStep({token: token, planification_id: planification_id, step_type:'delay', timedelta: '10:00:00'})
                const url = `/strategies/${planification_id}`;
                navigate(url);
            }).catch(err => {
                setErrorOnCreate(t("Ya existe un elemento con ese nombre"))
                console.error(err)

            }).finally(() => {

                
            })

    };


    const selectStrategy = (id: number) => {

        const url = `/strategies/${id}`;
        navigate(url);
    };


    //const saveStrategy = async (id: number, editedRow: string) => {
    //    const response = await Planification.editPlanification({ planification_id: id, name: editedRow })
    //    return response
    //};

    const deleteStrategy = async (id: number) => {
        return await Planification.deletePlanification(getAccessTokenSilently, id)
    };

    return (
        <BaseView view="/strategies/">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
            <Breadcrumbs elements={[
                    { "name": t("Estrategias") },
                ]} />
                <IconButton aria-label="settings" onClick={()=>setInfoOpen(true)}>
                    <InfoRoundedIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2"> {t("Estrategias")} </Typography>
                <Button
                    onClick={() => openNewFormDialog()}
                    color="primary"
                    startDecorator={<AddIcon />}
                    size="sm"
                >
                    {t("Nueva estrategia")}
                </Button>
                <CreateModal
                    open={openDialog}
                    onClose={closenNewFormDialog}
                    onSubmit={newStrategy}
                    error={errorOnCreate}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                my: 1,
                gap: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
            }}>
                {loading ? (
                    <>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <Skeleton key={index} animation="wave" level="h2" variant="text" />
                        ))}
                    </>
                ) : (
                    <ListItems
                        items={strategies}
                        onSelect={selectStrategy}
                        onDelete={deleteStrategy}
                    />
                )}
            </Box>
            <InfoModal
            isOpen={infoOpen}
            content={t('Nuestra página de Estrategias es una herramienta interactiva que te permite crear y visualizar flujos de comunicación a medida. Aquí, podrás definir con precisión la frecuencia y las condiciones para el envío de acciones específicas como correos electrónicos, SMS o llamadas. Esta funcionalidad te brinda el control para ajustar tus estrategias de recolección de deuda, asegurando interacciones oportunas y adaptadas a cada situación particular de los deudores.')}
            onClose={()=>setInfoOpen(false)}
            />
        </BaseView>
    );
}
