import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import StrategyEditorPage from "./StrategyEditorPage";
import Planification from "../services/Planification";

import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from "@mui/material";

import BaseView from "./BaseView";
import { ContentTypeProvider } from "../context/contentTypeContext";
import { useAuth0 } from "@auth0/auth0-react";

export default function StrategyEditor() {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [planId, setPlanId] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [planificationName, setPlanificationName] = useState("");

  type planificationData = {
    client_id: number;
    id: number;
    name: string;
  };
  
  useEffect(() => {
    setPlanId(id ? Number(id) : undefined);
  }, [id]);
  
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          if (planId) {
            const data: planificationData = await getPlanificationName(planId);
            setPlanificationName(data.name);
          }
        } catch (error) {
          console.error('Error fetching planification data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }
  }, [planId]);
  
  const getPlanificationName = async (id: number) => {
  
      const response = await Planification.getPlanificationById(getAccessTokenSilently, id);
      return response;
  
  };

  return (
    <BaseView view="/strategies/">
      <ContentTypeProvider>
        <StrategyEditorPage 
          planificationId={planId}
          planificationName={planificationName}
        />
      </ContentTypeProvider>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </BaseView>
  );
}
