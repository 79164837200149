import * as React from 'react';
import { useTranslation } from "react-i18next";

import { visuallyHidden } from '@mui/utils';
import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Grid from '@mui/joy/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import EventAvatar from './EventAvatar';


export interface Data {
    timestamp: string;
    id: number,
    action_name: string;
    action_type: string;
    debtors: Record<string, any>[];
}

export interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    dataType: string;
}

interface EnhancedTableProps {
    columns: HeadCell[];
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

interface RowProps {
    row: Record<keyof Data, any>;
    columns: HeadCell[];
    timezone: string;
}

interface CollapsibleTableProps {
    rows: Record<keyof Data, any>[];
    columns: HeadCell[];
    timezone: string;
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
  }

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
        return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function Row(props: RowProps) {

    const { row, columns, timezone } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="sm"
                        onClick={() => setOpen(!open)}
                    >
                        
                    </IconButton>
                </TableCell>
                
                {columns.map((column) => (
                    <TableCell 
                        align={column.dataType === 'numeric' ? 'right' : 'left'}
                        padding={column.disablePadding ? 'none' : 'normal'}>

                            <Grid sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'flex-start',
                                }}>
                                <Grid xs={6} md={6} lg={6}>
                                {column.dataType !== 'date' ? 
                                    row[column.id] :              
                                    (row[column.id].toLocaleDateString("es-ES", {timeZone: timezone}) + " - " +
                                    row[column.id].toLocaleTimeString("es-ES", {timeZone: timezone}))
                                }
                                </Grid>

                                {column.id === "action_type" &&
                                    <Grid xs={6} md={6} lg={6} sx={{
                                        display: 'flex',
                                        justifyContent: "space-evenly",
                                        alignItems: 'center',
                                        alignContent: 'flex-start'
                                    }}>
                                        <>
                                        <div>&nbsp;</div>
                                        <div>&nbsp;</div>
                                        <div>&nbsp;</div>
                                        <EventAvatar actionType={row.action_type}/>
                                        </>
                                    </Grid>
                                }
                            </Grid>

                        </TableCell>
                ))}

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    {// Not usable now
                    /*
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography level="h4" gutterBottom component="div">
                                {t("Deudores")}
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("Nombre")}</TableCell>
                                        <TableCell>{t("Estado")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                
                                <TableBody>
                                    {row.debtors.map((debtorRow: Record<string, any>) => (
                                        <TableRow key={debtorRow.name}>
                                            <TableCell component="th" scope="row">
                                                {debtorRow.name}
                                            </TableCell>
                                            <TableCell>{debtorRow.status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                    
                            </Table>
                        </Box>
                    </Collapse>
                    */}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

  
function EnhancedTableHead(props: EnhancedTableProps) {
    const { t } = useTranslation();

    const { columns, order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.dataType === 'numeric' ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}



export default function CollapsibleTable(props: CollapsibleTableProps) {
    const { rows, columns, timezone } = props;

    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('timestamp');
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (
        _: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            rows.slice().sort(getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage, rows],
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Sheet sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-label="collapsible table"
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <EnhancedTableHead
                            columns={columns}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
  
                        <TableBody>
                            {visibleRows.map((row) => (
                                <Row key={row.timestamp} row={row} columns={columns} timezone={timezone} />
                            ))}

                            {emptyRows > 0 && (
                                <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                                >
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                    </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Sheet>
      </Box>
    );
}
