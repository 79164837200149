import React, { useState, useRef } from 'react';
import { useContentType } from '../context/contentTypeContext';
import ReactFlow, {
    ReactFlowProvider,
    useNodesState,
    getIncomers,
    getOutgoers,
    useEdgesState,
    ReactFlowInstance,
    EdgeTypes,
    NodeTypes,
    Controls,
} from 'reactflow';
import { Node, Edge } from 'reactflow';
import 'reactflow/dist/style.css';
import PlanificationEvents from '../services/Drawflow';
import './Drawflow/reactflow-style.css';
import DrawflowMenu from './DrawflowMenu';


//Flow components
import MailNodeComponent from '../components/Flow/MailNodeComponent';
import SmsNodeComponent from '../components/Flow/SmsNodeComponent';
import CallNodeComponent from '../components/Flow/CallNodeComponent';
import AddingNodeComponent from '../components/Flow/AddingNodeComponent';
import DelayNodeComponent from '../components/Flow/DelayNodeComponent';
import { HiddenNodeType, AddingNodeType, AddEdge, YesEdge, NoEdge } from '../components/Flow/FlowTypes';
import ConditionNodeComponent from '../components/Flow/ConditionNodeComponent';
import CreateNodeMenu from '../components/Flow/CreateNodeMenu';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Template from '../services/Template';
import { contentTypeToString, hasChildren, stringToContentType } from '../utils';
import { Step, Transition, Position, StrategyEditorPageProps } from '../interfaces';
import RetryNodeComponent from '../components/Flow/RetryNodeComponent';
import StartNodeComponent from '../components/Flow/StartNodeComponent';
import LoadingNodeComponent from '../components/Flow/LoadingNodeComponent';
import NoTemplateWarningModal from '../components/ActionModals/NoTemplateWarningModal';
import Breadcrumbs from '../components/Header/Breadcrumbs';
import { Box, IconButton, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import SettingsModal from '../components/ActionModals/SettingsModal';
import Planification from '../services/Planification';
import { useAuth0 } from '@auth0/auth0-react';
import { useClient } from '../context/ClientContext';


const nodeTypes: NodeTypes = {
    addingnode: AddingNodeType,
    hiddennodetype: HiddenNodeType
};
const edgeTypes: EdgeTypes = {
    addedge: AddEdge,
    yesedge: YesEdge,
    noedge: NoEdge,
};



export default function StrategyEditorPage(props: StrategyEditorPageProps) {

    type NodeLabelMapping = {
        [key: string]: JSX.Element | ((text?: string) => JSX.Element);
    };

    let addingId: number = 0
    const { t } = useTranslation();
    const client = useClient()
    const mapToNodeLabel: NodeLabelMapping = {
        'addingNode': <AddingNodeComponent />,
        'mail': <MailNodeComponent text="optional text" />,
        'sms': <SmsNodeComponent text="optional text" />,
        'call': <CallNodeComponent />,
        'delay': <DelayNodeComponent delay="optional text" />,
        'condition': <ConditionNodeComponent />,
        'retry': <RetryNodeComponent />,
        'start': <StartNodeComponent />,
        'loading': <LoadingNodeComponent />
    };

    const { planificationId, planificationName } = props;
    const { getAccessTokenSilently } = useAuth0()
    const [planName, setPlanName] = useState(planificationName)
    const [currentSteps, setCurrentSteps] = useState<{ [key: number]: Step } | undefined>(undefined)
    const [currentTransitions, setCurrentTransitions] = useState<{ [key: number]: Transition } | undefined>(undefined)
    const [nodes, setNodes, onNodesChange] = useNodesState([])
    const { contentTypeMapping } = useContentType();
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [openAddMenu, setOpenAddMenu] = useState(false)
    const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>(undefined)
    const [nodeSelected, setNodeSelected] = useState<Node | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const [noTemplateModalOpen, setNoTemplateModalOpen] = useState(false);
    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const [errorOnUpdate, setErrorOnUpdate] = useState('')
    
    const [templateSelected, setTemplateSelected] = useState<string | undefined>(undefined);



    const [menuOpen, setMenuOpen] = useState(false)
    const reactFlowWrapper = useRef<HTMLDivElement | null>(null)!;
    const [flowInstance, setFlowInstance] = useState<ReactFlowInstance | undefined>(
        undefined
    );
    const [planStepId, setplanStepId] = useState<number | undefined>(undefined)
    const nodeIds: number[] = []

    const onInit = (reactFlowInstance: ReactFlowInstance) => {

        setFlowInstance(reactFlowInstance)
        onEdgesChange
    };

    React.useEffect(() => {
        if (planificationId) {
            fetchNodes()
        }
    }, [planificationId, t])

    const closeDrawflowMenu = async () => {
        await setplanStepId(undefined)
         unselectNodes()
         setMenuOpen(false)
    }

    const getNewAddingId = () => {
        addingId -= -1
        return `adding-${addingId}`; 
    }

    const onSelectionChange = (elements: Record<string, any>) => {
        if (elements.nodes.length == 0) {
            setSelectedNodeId(undefined)
            setNodeSelected(undefined)
            return
        }
        if (elements.nodes[0]?.id == selectedNodeId) {
            return
        }
        if (elements.nodes[0]?.type == 'addingnode') {
            setOpenAddMenu(true)
            setSelectedNodeId(elements.nodes[0].id)
            setNodeSelected(elements.nodes[0])
        } else {
            setOpenAddMenu(false)
            setplanStepId(elements.nodes[0]?.id)
            setSelectedNodeId(elements.nodes[0]?.id)
            setNodeSelected(elements.nodes[0])
            if (elements.nodes[0]?.data.contentTypeId !== '17') {
                setMenuOpen(true)
            }
        }
    };

    const updateStrategy = async (name: string) => {

        await Planification.editPlanification(getAccessTokenSilently, planificationId, name )
            .then(data => {

                setPlanName(data.name)
                setOpenSettingsModal(false)
            }).catch(err => {
                setErrorOnUpdate(t("Ya existe un elemento con ese nombre"))
                console.error(err)

            }).finally(() => {

                
            })

    };

    const createAddNode = (id: string, posx: number, posy: number, typeEdge: string) => {
        //id in prop is source id
        const addingId = getNewAddingId()

        const newNode = {
            id: addingId,
            type: 'addingnode',
            data: {
                label: mapToNodeLabel['addingNode'],
                sourceNode: id
            },
            position: { x: posx, y: posy },
        }


        const newEdge = {
            id: `${id}-${addingId}`,
            source: id,
            target: addingId,
            animated: true,
            data: {
                label: 'edge label',
            },
            type: typeEdge,
        }
        return [newNode, newEdge]
    }

    const fetchNodes = async () => {
        const steps: Step[] = await PlanificationEvents.getPlanSteps(getAccessTokenSilently, planificationId);
        const transitions:
            Transition[] = await PlanificationEvents.getPlanTransitions(getAccessTokenSilently, planificationId);
        setCurrentSteps(steps)
        setCurrentTransitions(transitions)
        renderNodes(steps, transitions)
    }

    function countConditionsInAll(
        steps: { [key: number]: Step },
        transitions: { [key: number]: Transition },
        currentStepId: number,

    ): number {
        let count = 0;
        const queue = [currentStepId];

        while (queue.length > 0) {
            const stepId = queue.shift();
            const currentStep = steps[stepId!];

            if (!currentStep) {
                continue;
            }

            if (currentStep.step_type === 'condition' || currentStep.step_type === 'retry') {
                count++;
                if (currentStep.step_data.child_true !== null) {
                    queue.push(currentStep.step_data.child_true);
                }
                if (currentStep.step_data.child_false !== null) {
                    queue.push(currentStep.step_data.child_false);
                }
            } else {

                Object.values(transitions).forEach(transition => {
                    if (transition.src_id === stepId) {
                        queue.push(transition.dst_id);
                    }
                });
            }
        }

        return count;
    }

    const renderNodes = (steps: { [key: number]: Step }, transitions: { [key: number]: Transition }) => {
        const importedNodes = []
        const importedEdges = []
        for (const key in transitions) {
            const source_id = transitions[key].src_id.toString()
            const target_id = transitions[key].dst_id.toString()
            importedEdges.push({
                id: `e${source_id}-${target_id}`,
                source: source_id,
                target: target_id,
                type: 'addedge',

            })
        }

        const position: { [id: string]: Position } = {};
        const baseOffset = 150;
        let isFirstElement = true;

        for (const key in steps) {

            nodeIds.push(parseInt(key))
            const parent_id = steps[key].parent_id;
            if (!parent_id) {
                position[key] = { posx: 0, posy: 0 };
            } else if (steps[parent_id].step_type === 'condition' || steps[parent_id].step_type === 'retry') {
                if (parseInt(key) === steps[parent_id].step_data.child_true) {

                    const conditionCount = countConditionsInAll(steps, transitions, steps[parent_id].step_data.child_false!);
                    position[key] = {
                        posx: position[steps[key].parent_id].posx - (conditionCount * baseOffset) - baseOffset,
                        posy: position[steps[key].parent_id].posy + 150,
                    };
                } else {
                    const conditionCount = countConditionsInAll(steps, transitions, steps[parent_id].step_data.child_true!);
                    position[key] = {
                        posx: position[steps[key].parent_id].posx + (conditionCount * baseOffset) + baseOffset,
                        posy: position[steps[key].parent_id].posy + 150,
                    };
                }
            } else {
                position[key] = {
                    posx: position[steps[key].parent_id].posx,
                    posy: position[steps[key].parent_id].posy + 150,
                };
            }

            if (steps[key].step_type == 'action') {
                const nodeType = contentTypeToString(contentTypeMapping, steps[key].step_data.content_type_id)
                let nodeComponent = undefined
                if (nodeType === 'mail') {
                    nodeComponent = <MailNodeComponent text={steps[key].step_data.object_name} />
                } else if (nodeType === 'sms') {
                    nodeComponent = <SmsNodeComponent text={steps[key].step_data.object_name} />
                } else if (nodeType === 'call') {
                    nodeComponent = <CallNodeComponent />
                }

                importedNodes.push({
                    id: key,
                    data: {
                        data: steps[key],
                        contentTypeId: steps[key].step_data.content_type_id.toString(),
                        type: 'customnode',
                        label: nodeComponent,

                    },
                    position: { x: position[key].posx, y: position[key].posy },
                })

                if (!hasChildren(transitions, parseInt(key))) {
                    let addNodeAndEdge: any = createAddNode(key, position[key].posx, position[key].posy + 150, 'addedge')
                    importedNodes.push(addNodeAndEdge[0])
                    importedEdges.push(addNodeAndEdge[1])
                }
            } else if (steps[key].step_type == 'delay') {
                let label;
                if (steps[key].step_type === 'delay') {
                    const timedelta = steps[key].step_data.timedelta
                    const translatedTimedelta = timedelta.replace('day', t('día'));
                    label = isFirstElement ? mapToNodeLabel['start'] : <DelayNodeComponent delay={translatedTimedelta} />
                    importedNodes.push({
                        id: key,
                        data: {
                            data: steps[key],
                            timedelta: steps[key].step_data.timedelta,
                            type: 'customnode',
                            label: label
                        },
                        position: { x: position[key].posx, y: position[key].posy },
                    });
                    isFirstElement = false
                }
                if (!hasChildren(transitions, parseInt(key))) {
                    let addNodeAndEdge: any = createAddNode(key, position[key].posx, position[key].posy + 150, 'addedge')
                    importedNodes.push(addNodeAndEdge[0])
                    importedEdges.push(addNodeAndEdge[1])
                }
            } else if (steps[key].step_type == 'condition' || steps[key].step_type === 'retry') {

                importedNodes.push({
                    id: key,
                    data: {
                        data: steps[key],
                        type: 'customnode',
                        label: mapToNodeLabel[steps[key].step_type]
                    },
                    position: { x: position[key].posx, y: position[key].posy },
                })

                if (steps[key].step_data.child_true == null) {

                    let addNodeAndEdge: any = createAddNode(key, position[key].posx - 150, position[key].posy + 150, 'addedge')
                    importedNodes.push(addNodeAndEdge[0])
                    importedEdges.push(addNodeAndEdge[1])

                }

                if (steps[key].step_data.child_false == null) {
                    let addNodeAndEdge: any = createAddNode(key, position[key].posx + 150, position[key].posy + 150, 'addedge')
                    importedNodes.push(addNodeAndEdge[0])
                    importedEdges.push(addNodeAndEdge[1])
                }

            }

        }

        setNodes(importedNodes);
        setEdges(importedEdges);

    }

    const unselectNodes = () => {
        const updatedNodes = nodes.map((node) => {
          if (node.selected) {
            const { selected, ...rest } = node;
            return rest;
          }
          return node;
        });
        setNodes(updatedNodes);
      };
      


    const onEventUpdated = async (nodeData: Step) => {
        await closeDrawflowMenu() //it has to be done first, because if not when unselecting the nodes there is a conflict with renderNodes and transitions diasappear
        let updatedSteps = { ...currentSteps };
        let updatedTransitions = { ...currentTransitions };
        updatedSteps[nodeData.id] = nodeData;
        setCurrentSteps(updatedSteps)
        renderNodes(updatedSteps, updatedTransitions)
        

    }

    const onEventCreated = async (nodeData: Step) => {

        // si es condicion derecha, añadir en step child false y si izquierda en step child true
        //if parent is condition, 
        let branch = true

        let updatedSteps = { ...currentSteps };
        let updatedTransitions = { ...currentTransitions };
        const sourceId = nodeSelected?.data.sourceNode
        let sourceNodeInstance = flowInstance?.getNode(sourceId)
        if (nodeSelected!.position.x > sourceNodeInstance!.position.x) { //if node to add is positioned at the right of the upper node that means is the "no" branch
            branch = false
            updatedSteps[sourceId].step_data.child_false = nodeData.id
        } else if (nodeSelected!.position.x < sourceNodeInstance!.position.x) {
            branch = true
            updatedSteps[sourceId].step_data.child_true = nodeData.id
        }

        const newTransition = await PlanificationEvents.connectPlanStep({getAccessToken: getAccessTokenSilently, planification_id: planificationId, src_id: parseInt(sourceId), dst_id: nodeData.id, branch: branch})
        nodeData.parent_id = parseInt(sourceId);
        updatedSteps[nodeData.id] = nodeData;
        updatedSteps[nodeData.id] = nodeData;
        updatedTransitions[newTransition.id] = newTransition;
        setCurrentSteps(updatedSteps)
        setCurrentTransitions(updatedTransitions)
        renderNodes(updatedSteps, updatedTransitions)
        setLoading(false)

        let nodeType: string | undefined = ""
        if (nodeData.step_type == 'action') {
            nodeType = contentTypeToString(contentTypeMapping, nodeData.step_data.content_type_id)
        } else {
            nodeType = nodeData.step_type
        }
        if (nodeType !== 'call') {
            setMenuOpen(true)
        }

    };

    const handleRemoveNodeAndChildren = (
        nodeId: string,
        nodes: Node[],
        edges: Edge[]
    ): Node[] => {
        const nodeToRemove = nodes.find(node => node.id === nodeId);
        if (!nodeToRemove) return nodes;

        const childNodes = getOutgoers(nodeToRemove, nodes, edges);

        for (const childNode of childNodes) {
            nodes = handleRemoveNodeAndChildren(childNode.id, nodes, edges);
        }

        return nodes.filter(node => node.id !== nodeId);
    };

    const deleteChildrenBasedOnBranch = (
        steps: { [x: number]: Step },
        transitions: { [x: number]: Transition },
        stepId: number,
        branch: number
    ): { steps: { [x: number]: Step }, transitions: { [x: number]: Transition } } => {
        const stepToDelete = steps[stepId];
        if (!stepToDelete || stepToDelete.step_type !== 'condition' && stepToDelete.step_type !== 'retry') return { steps, transitions };

        let childBranchIds = [];
        if (branch === 1) {
            if (stepToDelete.step_data.child_false != null) {
                childBranchIds.push(stepToDelete.step_data.child_false);
            }
        } else if (branch === 0) {
            if (stepToDelete.step_data.child_true != null) {
                childBranchIds.push(stepToDelete.step_data.child_true);
            }
        } else if (branch === -1) {
            if (stepToDelete.step_data.child_true != null) {
                childBranchIds.push(stepToDelete.step_data.child_true);
            }
            if (stepToDelete.step_data.child_false != null) {
                childBranchIds.push(stepToDelete.step_data.child_false);
            }
        }

        const deleteRecursively = (childStepId: number) => {
            Object.values(steps).forEach((childStep) => {
                if (childStep.parent_id === childStepId) {
                    deleteRecursively(childStep.id);
                }
            });

            Object.keys(transitions).forEach(key => {
                const transitionId = parseInt(key);
                if (transitions[transitionId] && (transitions[transitionId].src_id === childStepId || transitions[transitionId].dst_id === childStepId)) {
                    delete transitions[transitionId];
                }
            });

            delete steps[childStepId];
        };

        childBranchIds.forEach(childBranchId => {
            if (childBranchId != null) {
                deleteRecursively(childBranchId);
            }
        });


        return { steps, transitions };
    };



    const onEventDeleted = async (branch?: number) => {

        let branchSelected = branch !== undefined ? branch : 1;

        let updatedSteps = { ...currentSteps };
        let updatedTransitions = { ...currentTransitions };
        if (!nodeSelected || !selectedNodeId) {
            console.error("Node selected or selectedNodeId is undefined");
            return;
        }

        const incomers = getIncomers(nodeSelected, nodes, edges);
        if (incomers.length === 0) {
            console.error("No incomers found for the selected node");
            return;
        }
        const incomerId = parseInt(incomers[0].id);

        const outgoers = getOutgoers(nodeSelected, nodes, edges);

        //if incomer is condition or retry set child_false/child_true to null
        if (updatedSteps[incomerId].step_data.child_true === parseInt(selectedNodeId)) {
            updatedSteps[incomerId].step_data.child_true = null
        } else if (updatedSteps[incomerId].step_data.child_false === parseInt(selectedNodeId)) {
            updatedSteps[incomerId].step_data.child_false = null
        }

        outgoers.forEach(outgoer => {
            const outgoerId = parseInt(outgoer.id);
            if (updatedSteps[outgoerId]) {
                updatedSteps[outgoerId].parent_id = incomerId;
            }
        });
        const selectedNodeIdNumber = parseInt(selectedNodeId);
        const result = deleteChildrenBasedOnBranch(updatedSteps, updatedTransitions, parseInt(selectedNodeId), branchSelected);
        updatedSteps = result.steps;
        updatedTransitions = result.transitions;

        delete updatedSteps[selectedNodeIdNumber];

        Object.entries(updatedTransitions).forEach(([key, value]) => {
            const transition = value as Transition;
            const keyNumber = parseInt(key);
            if (transition.src_id === selectedNodeIdNumber) {
                transition.src_id = incomerId;
            } else if (transition.dst_id === selectedNodeIdNumber) {
                delete updatedTransitions[keyNumber];
            }
        });
        setMenuOpen(false)
        setCurrentTransitions(updatedTransitions);
        setCurrentSteps(updatedSteps);

        // Re-render steps

        await PlanificationEvents.deletePlanStep(getAccessTokenSilently, selectedNodeIdNumber, branchSelected)
        renderNodes(updatedSteps, updatedTransitions);

    }
    const changeNodeLabel = (selectedId: string) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === selectedId) {
                    node.data = {
                        ...node.data,
                        label: mapToNodeLabel['loading'],
                    };
                }

                return node;
            })
        );
    }


    const createNode = async (typeNode: string) => {

        let actionId: number | undefined = undefined
        let contentTypeId: number | undefined = undefined
        let step_type = 'action'
        let templates = []
        if (typeNode == 'mail') {
            templates = await Template.getMail(getAccessTokenSilently, client.client!.id)
            contentTypeId = stringToContentType(contentTypeMapping, 'mail')
        } else if (typeNode == 'sms') {
            templates = await Template.getSMS(getAccessTokenSilently,  client.client!.id)
            contentTypeId = stringToContentType(contentTypeMapping, 'sms')
        } else if (typeNode == 'call') {
            templates = await Template.getCall(getAccessTokenSilently,  client.client!.id)
            contentTypeId = stringToContentType(contentTypeMapping, 'call')

        }
        if (typeNode === 'sms' || typeNode === 'mail' || typeNode === 'call') {
            if (templates.length === 0) {
                setLoading(false)
                setTemplateSelected(typeNode)
                setNoTemplateModalOpen(true)
                setOpenAddMenu(false)
                return
            }
            actionId = templates[0].id
        }

        setplanStepId(0)
        setLoading(true)
        changeNodeLabel(selectedNodeId!)



        let timeDelta = ''
        if (typeNode == 'delay') {
            timeDelta = '24:00:00'
            step_type = 'delay'
        }



        let operation = ''
        let target = ''
        let field = ''
        let value = ''
        if (typeNode == 'condition') {
            step_type = 'condition'
            operation = ''
            target = ''
            field = ''
            value = ''
        }

        let n_attempts = 0
        let timedeltas: string[] = ['']
        if (typeNode == 'retry') {
            timedeltas = ['']
            step_type = 'retry'
            operation = ''
            target = ''
            field = ''
            value = ''
            timedeltas = ['00:10:00']
            n_attempts = 0
        }
        const token = await getAccessTokenSilently()
        const response = await PlanificationEvents.createPlanStep({token: token, planification_id: planificationId, step_type: step_type, action_id: actionId, content_type_id: contentTypeId, timedelta: timeDelta, operation: operation, target: target, field: field, value: value, timedeltas: timedeltas, n_attempts: n_attempts })
        setplanStepId(response.id)
        setOpenAddMenu(false)
        onEventCreated(response)

    }

    return (<>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs elements={[
                { "name": t("Estrategias"), "href": "/strategies/" },
                { "name": `${planificationName}` }
            ]} />
        </Box>
        <Box
            sx={{
                display: 'flex',
                my: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            <Typography level="h2">{planName}</Typography>
            <IconButton aria-label="settings" onClick={()=> setOpenSettingsModal(true)}>
              <SettingsRoundedIcon />
            </IconButton>
        </Box>
        <CreateNodeMenu
            isOpen={openAddMenu}
            disabled={loading}
            onSelect={createNode}
            onClose={() => setOpenAddMenu(false)}
        />
        <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ height: '100%', width: '100%', position: 'relative' }}>
                <ReactFlow
                    fitView
                    fitViewOptions={{ duration: 400 }}
                    nodesDraggable={false}
                    onInit={onInit}
                    nodes={nodes}
                    edges={edges}
                    onSelectionChange={onSelectionChange}
                    onNodesChange={onNodesChange}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    deleteKeyCode={null}
                >
                    <Controls />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
        <DrawflowMenu
            open={menuOpen}
            onClose={() => closeDrawflowMenu()}
            planStepId={planStepId}
            onUpdated={onEventUpdated}
            onDeleted={onEventDeleted}
        />
        <NoTemplateWarningModal
            isOpen={noTemplateModalOpen}
            template={templateSelected!}
            onClose={() => setNoTemplateModalOpen(false)}
        />
        <SettingsModal
         open={openSettingsModal}
         defaultValue={planName!}
         onClose={()=> setOpenSettingsModal(false)}
         onSubmit={updateStrategy}
         error={errorOnUpdate}
        />
    </>

    );
}

