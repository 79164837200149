import React from 'react';

const Admin = () => {

    React.useEffect(() => {
        window.location.href = import.meta.env.VITE_API_URL + '/admin/'

    }, []);
    return('')
};

export default Admin;
