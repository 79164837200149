import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import MuiBreadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import { useNavigate } from 'react-router-dom';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

interface BreadcrumbsProps {
    elements: Array<Record<string, string>>;
}


export default function Breadcrumbs(props: BreadcrumbsProps) {

    const navigate = useNavigate();
    const { elements } = props

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MuiBreadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon/>}
                sx={{ pl: 0, mt: { xs: 3, md: 3, lg:0 }}}
            >
                <Link
                    underline="none"
                    color="neutral"
                    onClick={() => navigate('/')} 
                    aria-label="Home"
                >
                    <HomeRoundedIcon />
                </Link>

                {elements.map((element) => {

                    if (element.href != undefined) {
                        return (
                            <>
                            <Link
                                underline="none"
                                color="neutral"
                                onClick={() => navigate(element.href)}
                                aria-label={element.name}
                            >
                                {element.name}
                            </Link>
                            </>
                        )
                    } else {
                        return (
                            <>
                            <Typography>
                                {element.name}
                            </Typography>
                            </>
                        )
                    }
                })}
            </MuiBreadcrumbs>
        </Box>
    )

}