import { List, ListItem, ListSubheader, ListItemButton, ListItemDecorator, ListItemContent } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import { AccessTimeRounded, AltRouteRounded, MailRounded, PhoneRounded, SmsRounded, ReplayRounded } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';



interface CreateNodeMenuProps {
    isOpen: boolean;
    onSelect: (typeNode: string) => void;
    disabled: boolean
    onClose: () => void;
}


export default function CreateNodeMenu({ isOpen, onSelect, disabled, onClose }: CreateNodeMenuProps) {


    const [isDisabled, setIsDisabled] = useState(disabled)
    const { t } = useTranslation()

    useEffect(()=>{
        setIsDisabled(disabled)
    },[disabled])
    
    return (

        <Sheet
            variant="outlined"
            sx={{
                overflow: 'auto',
                borderRadius: 'sm',
                maxWidth: 500,
                p: 3,
                boxShadow: 'lg',
                position: 'absolute',
                top: '30%',
                right: 200,
                zIndex: 9999,
                opacity: isOpen ? 1 : 0,
                pointerEvents: isOpen ? 'auto' : 'none',
                transition: 'opacity 0.3s ease',
            }}
        >
            <ModalClose
                variant="outlined"
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    m: 1,
                    mb: 2,
                    zIndex: 2
                }}
                onClick={() => onClose()}
            />
            <List>
                <ListItem nested>
                    <ListSubheader sticky>{t('Eventos')}</ListSubheader>
                    <List>
                        <ListItem>
                            <ListItemButton disabled={isDisabled} onClick={() => onSelect('mail')}>
                                <ListItemDecorator> <MailRounded sx={{color: '#1e88e5'}} /></ListItemDecorator>
                                <ListItemContent>Mail</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton disabled={isDisabled} onClick={() => onSelect('sms')}>
                                <ListItemDecorator> <SmsRounded sx={{color: '#fb8c00'}}/></ListItemDecorator>
                                <ListItemContent>SMS</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton disabled={isDisabled} onClick={() => onSelect('call')}>
                                <ListItemDecorator> <PhoneRounded sx={{color: '#43a047'}}/></ListItemDecorator>
                                <ListItemContent>{t('Llamada')}</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <ListSubheader sticky>{t('Condiciones')}</ListSubheader>
                    <List>
                        <ListItem>
                            <ListItemButton  disabled={isDisabled} onClick={() => onSelect('delay')}>
                                <ListItemDecorator> <AccessTimeRounded /></ListItemDecorator>
                                <ListItemContent>{t('Demora')}</ListItemContent></ListItemButton>
                        </ListItem>
                        <ListItem>
                                <ListItemButton disabled={isDisabled} onClick={() => onSelect('condition')}>
                                    <ListItemDecorator> <AltRouteRounded /></ListItemDecorator>
                                    <ListItemContent>{t('Si/ si no')}</ListItemContent>
                                </ListItemButton>
                        </ListItem>
                        <ListItem>
                                <ListItemButton disabled={isDisabled} onClick={() => onSelect('retry')}>
                                    <ListItemDecorator> <ReplayRounded /></ListItemDecorator>
                                    <ListItemContent>{t('Reintento')}</ListItemContent>
                                </ListItemButton>
                        </ListItem>
                    </List>
                </ListItem>
            </List>
        </Sheet>
    );
}
