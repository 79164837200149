import React from 'react';

export const MaiaLogoIcon: React.FC = () => {
    return (
        <svg width="32.8" height="29" viewBox="-32.6 -29 196.8 178.8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_24)">
                <g opacity="0.28">
                    <path d="M145.52 39.8298C160.864 66.4756 177.432 95.386 141.845 114.259C106.257 133.132 31.9882 134.825 16.6415 108.176C1.29484 81.527 17.7067 44.6132 53.294 25.7537C88.8814 6.8943 130.181 13.1808 145.52 39.8298Z" fill="#4393E4" />
                </g>
                <g opacity="0.35">
                    <path d="M83.9942 2.92614C116.038 12.2957 111.51 49.5871 99.3897 87.7502C87.2696 125.913 72.1492 150.501 40.1053 141.125C8.06131 131.749 -8.09294 93.2183 4.0271 55.0552C16.1471 16.892 51.9503 -6.44679 83.9942 2.92614Z" fill="#4393E4" />
                </g>
                <g opacity="0.7">
                    <path d="M136.655 79.9805C134.915 110.017 73.8132 83.127 74.7773 139.361C75.3412 172.15 12.9032 112.776 12.9032 79.9805C12.9027 72.1826 14.5028 64.461 17.6121 57.2566C20.7213 50.0522 25.2789 43.5061 31.0245 37.9921C36.7701 32.478 43.5912 28.104 51.0983 25.1198C58.6055 22.1356 66.6516 20.5996 74.7773 20.5996C108.948 20.5996 138.535 47.2353 136.655 79.9805Z" fill="#4393E4" />
                </g>
                <path d="M88.6725 61.4317H88.7944C88.9772 61.4317 89.1583 61.4662 89.3272 61.5334C89.4961 61.6005 89.6496 61.699 89.7789 61.823C89.9082 61.9471 90.0107 62.0944 90.0807 62.2565C90.1507 62.4186 90.1867 62.5924 90.1867 62.7678V73.8243C90.1867 74.1786 90.04 74.5185 89.7789 74.7691C89.5178 75.0196 89.1636 75.1604 88.7944 75.1604H88.6725C88.3033 75.1604 87.9491 75.0196 87.688 74.7691C87.4269 74.5185 87.2802 74.1786 87.2802 73.8243V62.7678C87.2802 62.4135 87.4269 62.0736 87.688 61.823C87.9491 61.5725 88.3033 61.4317 88.6725 61.4317Z" fill="white" />
                <path d="M79.264 74.95C79.0838 74.9504 78.9053 74.9167 78.7387 74.8507C78.5721 74.7847 78.4207 74.6878 78.2933 74.5655C78.1658 74.4432 78.0648 74.2979 77.9961 74.138C77.9273 73.9782 77.8922 73.8068 77.8926 73.6339C77.8926 68.8138 74.6555 63.9035 71.481 63.9035C68.404 63.9035 65.3827 68.7236 65.3827 73.6339C65.3827 73.9829 65.2382 74.3177 64.9811 74.5645C64.7239 74.8113 64.375 74.95 64.0113 74.95C63.6476 74.95 63.2988 74.8113 63.0416 74.5645C62.7844 74.3177 62.6399 73.9829 62.6399 73.6339C62.6458 70.7342 63.4723 67.8906 65.0312 65.4067C66.7716 62.7344 69.048 61.268 71.481 61.268C73.9141 61.268 76.2845 62.7678 78.0701 65.3766C79.7233 67.8402 80.6129 70.7038 80.6355 73.6339C80.6359 73.8068 80.6008 73.9782 80.532 74.138C80.4632 74.2979 80.3622 74.4432 80.2348 74.5655C80.1074 74.6878 79.956 74.7847 79.7894 74.8507C79.6228 74.9167 79.4443 74.9504 79.264 74.95Z" fill="white" />
                <path d="M114.517 74.95C114.154 74.95 113.805 74.8113 113.547 74.5645C113.29 74.3177 113.146 73.9829 113.146 73.6339C113.146 68.8138 109.912 63.9035 106.738 63.9035C103.661 63.9035 100.639 68.7236 100.639 73.6339C100.622 73.9719 100.469 74.2904 100.214 74.5237C99.9581 74.7569 99.6189 74.8869 99.2663 74.8869C98.9136 74.8869 98.5744 74.7569 98.3188 74.5237C98.0633 74.2904 97.9108 73.9719 97.8931 73.6339C97.9002 70.7339 98.7279 67.8903 100.288 65.4067C102.028 62.7344 104.301 61.268 106.738 61.268C109.174 61.268 111.538 62.7678 113.323 65.3766C114.979 67.8393 115.87 70.7033 115.892 73.6339C115.892 73.807 115.857 73.9784 115.787 74.1383C115.718 74.2982 115.617 74.4434 115.489 74.5657C115.362 74.6879 115.21 74.7848 115.043 74.8507C114.876 74.9167 114.698 74.9504 114.517 74.95Z" fill="white" />
                <path d="M88.6725 61.4317H88.7944C88.9772 61.4317 89.1583 61.4662 89.3272 61.5334C89.4961 61.6005 89.6496 61.699 89.7789 61.823C89.9082 61.9471 90.0107 62.0944 90.0807 62.2565C90.1507 62.4186 90.1867 62.5924 90.1867 62.7678V73.8243C90.1867 74.1786 90.04 74.5185 89.7789 74.7691C89.5178 75.0196 89.1636 75.1604 88.7944 75.1604H88.6725C88.3033 75.1604 87.9491 75.0196 87.688 74.7691C87.4269 74.5185 87.2802 74.1786 87.2802 73.8243V62.7678C87.2802 62.4135 87.4269 62.0736 87.688 61.823C87.9491 61.5725 88.3033 61.4317 88.6725 61.4317Z" fill="white" />
                <path d="M79.264 74.95C79.0838 74.9504 78.9053 74.9167 78.7387 74.8507C78.5721 74.7847 78.4207 74.6878 78.2933 74.5655C78.1658 74.4432 78.0648 74.2979 77.9961 74.138C77.9273 73.9782 77.8922 73.8068 77.8926 73.6339C77.8926 68.8138 74.6555 63.9035 71.481 63.9035C68.404 63.9035 65.3827 68.7236 65.3827 73.6339C65.3827 73.9829 65.2382 74.3177 64.9811 74.5645C64.7239 74.8113 64.375 74.95 64.0113 74.95C63.6476 74.95 63.2988 74.8113 63.0416 74.5645C62.7844 74.3177 62.6399 73.9829 62.6399 73.6339C62.6458 70.7342 63.4723 67.8906 65.0312 65.4067C66.7716 62.7344 69.048 61.268 71.481 61.268C73.9141 61.268 76.2845 62.7678 78.0701 65.3766C79.7233 67.8402 80.6129 70.7038 80.6355 73.6339C80.6359 73.8068 80.6008 73.9782 80.532 74.138C80.4632 74.2979 80.3622 74.4432 80.2348 74.5655C80.1074 74.6878 79.956 74.7847 79.7894 74.8507C79.6228 74.9167 79.4443 74.9504 79.264 74.95Z" fill="white" />
                <path d="M114.517 74.95C114.154 74.95 113.805 74.8113 113.547 74.5645C113.29 74.3177 113.146 73.9829 113.146 73.6339C113.146 68.8138 109.912 63.9035 106.738 63.9035C103.661 63.9035 100.639 68.7236 100.639 73.6339C100.622 73.9719 100.469 74.2904 100.214 74.5237C99.9581 74.7569 99.6189 74.8869 99.2663 74.8869C98.9136 74.8869 98.5744 74.7569 98.3188 74.5237C98.0633 74.2904 97.9108 73.9719 97.8931 73.6339C97.9002 70.7339 98.7279 67.8903 100.288 65.4067C102.028 62.7344 104.301 61.268 106.738 61.268C109.174 61.268 111.538 62.7678 113.323 65.3766C114.979 67.8393 115.87 70.7033 115.892 73.6339C115.892 73.807 115.857 73.9784 115.787 74.1383C115.718 74.2982 115.617 74.4434 115.489 74.5657C115.362 74.6879 115.21 74.7848 115.043 74.8507C114.876 74.9167 114.698 74.9504 114.517 74.95Z" fill="#181613" />
                <path d="M54.2686 62.7979V73.6539C54.268 74.0206 54.1166 74.3723 53.8475 74.6326C53.7136 74.7611 53.5546 74.863 53.3796 74.9324C53.2045 75.0017 53.017 75.0372 52.8276 75.0368C52.6385 75.0368 52.4513 75.001 52.2767 74.9315C52.102 74.8619 51.9434 74.76 51.8099 74.6316C51.6763 74.5031 51.5705 74.3506 51.4985 74.1829C51.4264 74.0151 51.3896 73.8354 51.39 73.6539L51.4805 67.9954C51.4812 67.9208 51.4541 67.8484 51.4042 67.7912C51.3542 67.734 51.2847 67.6958 51.208 67.6833C51.1313 67.6708 51.0526 67.685 50.9858 67.7232C50.919 67.7614 50.8686 67.8212 50.8436 67.8919L45.6224 74.1049C45.2152 74.5558 44.881 75.0201 44.2754 75.0234C43.6697 75.0268 43.1476 74.4556 42.9109 74.1282L37.5505 67.8585C37.5254 67.7872 37.4744 67.7269 37.4069 67.6886C37.3393 67.6504 37.2597 67.6367 37.1825 67.6501C37.1053 67.6634 37.0357 67.7029 36.9863 67.7614C36.937 67.8199 36.9111 67.8935 36.9135 67.9687L36.8161 73.6472C36.8161 73.8287 36.7788 74.0083 36.7063 74.1759C36.6339 74.3435 36.5277 74.4958 36.3938 74.6239C36.2599 74.752 36.101 74.8536 35.9262 74.9227C35.7514 74.9919 35.5641 75.0272 35.375 75.0268C34.9955 75.0313 34.6296 74.8915 34.357 74.6381C34.0845 74.3847 33.9274 74.0381 33.9201 73.674V62.7979C33.921 62.432 34.0726 62.0812 34.3419 61.8222C34.6112 61.5631 34.9763 61.4167 35.3576 61.415C35.9598 61.415 36.2835 61.7757 36.7082 62.3202L43.7498 70.848C43.8577 70.9683 43.9238 71.0551 44.0596 71.0551C44.1953 71.0551 44.2719 70.9583 44.3659 70.8447L51.4562 62.3403C51.8356 61.8826 52.2045 61.415 52.8137 61.415C53.0041 61.4132 53.193 61.4477 53.3694 61.5164C53.5459 61.5851 53.7064 61.6867 53.8417 61.8153C53.977 61.9439 54.0843 62.0969 54.1576 62.2656C54.2309 62.4342 54.2687 62.6151 54.2686 62.7979Z" fill="#181613" />
                <path d="M88.6725 61.4317H88.7944C88.9772 61.4317 89.1583 61.4662 89.3272 61.5334C89.4961 61.6005 89.6496 61.699 89.7789 61.823C89.9082 61.9471 90.0107 62.0944 90.0807 62.2565C90.1507 62.4186 90.1867 62.5924 90.1867 62.7678V73.8243C90.1867 74.1786 90.04 74.5185 89.7789 74.7691C89.5178 75.0196 89.1636 75.1604 88.7944 75.1604H88.6725C88.3033 75.1604 87.9491 75.0196 87.688 74.7691C87.4269 74.5185 87.2802 74.1786 87.2802 73.8243V62.7678C87.2802 62.4135 87.4269 62.0736 87.688 61.823C87.9491 61.5725 88.3033 61.4317 88.6725 61.4317Z" fill="white" />
                <path d="M80.1655 71.4326V71.5495C80.1655 71.9039 80.0189 72.2437 79.7577 72.4943C79.4966 72.7449 79.1425 72.8856 78.7732 72.8856H71.3557C70.9865 72.8856 70.6323 72.7449 70.3712 72.4943C70.1101 72.2437 69.9634 71.9039 69.9634 71.5495V71.4326C69.9634 71.0782 70.1101 70.7384 70.3712 70.4878C70.6323 70.2372 70.9865 70.0965 71.3557 70.0965H78.7837C79.1511 70.0991 79.5026 70.241 79.7614 70.4913C80.0203 70.7416 80.1655 71.08 80.1655 71.4326Z" fill="white" />
                <path d="M115.161 71.4326V71.5495C115.161 71.9039 115.014 72.2437 114.753 72.4943C114.492 72.7449 114.138 72.8856 113.769 72.8856H106.351C105.982 72.8856 105.628 72.7449 105.367 72.4943C105.106 72.2437 104.959 71.9039 104.959 71.5495V71.4326C104.959 71.0782 105.106 70.7384 105.367 70.4878C105.628 70.2372 105.982 70.0965 106.351 70.0965H113.779C114.147 70.0991 114.498 70.241 114.757 70.4913C115.016 70.7416 115.161 71.08 115.161 71.4326Z" fill="#181613" />
                <path d="M79.264 74.95C79.0838 74.9504 78.9053 74.9167 78.7387 74.8507C78.5721 74.7847 78.4207 74.6878 78.2933 74.5655C78.1658 74.4432 78.0648 74.2979 77.9961 74.138C77.9273 73.9782 77.8922 73.8068 77.8926 73.6339C77.8926 68.8138 74.6555 63.9035 71.481 63.9035C68.404 63.9035 65.3827 68.7236 65.3827 73.6339C65.365 73.9719 65.2126 74.2904 64.957 74.5237C64.7014 74.7569 64.3623 74.8869 64.0096 74.8869C63.6569 74.8869 63.3177 74.7569 63.0621 74.5237C62.8066 74.2904 62.6542 73.9719 62.6364 73.6339C62.6435 70.7339 63.4712 67.8903 65.0312 65.4067C66.7716 62.7344 69.048 61.268 71.481 61.268C73.9141 61.268 76.2845 62.7678 78.0701 65.3766C79.7257 67.8393 80.6167 70.7033 80.6389 73.6339C80.6389 73.807 80.6034 73.9784 80.5342 74.1383C80.4651 74.2982 80.3638 74.4434 80.236 74.5657C80.1083 74.6879 79.9567 74.7848 79.7899 74.8507C79.6231 74.9167 79.4444 74.9504 79.264 74.95Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1_24">
                    <rect width="163.074" height="148.076" fill="white" transform="translate(0 0.85498)" />
                </clipPath>
            </defs>
        </svg>
    );
};




