//
//
//  Api
//
//

import {getCSRFToken} from "../utils";


export class RequestStatusFailure extends Error {

    response: any

    constructor(response: any) {
        super()
        this.response = response
    }

}

import {toJsonOrRaise} from "../utils";

export default class Template {

    static async getMail(getAccessToken: () => Promise<string>, client_id?: number) {
        
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/mail/?client_id=${client_id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }
      

    static async createMail(getAccessToken: () => Promise<string>, name?: string, subject?: string, body?: string, client_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/mail/`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name,
                subject,
                body,
                ...(client_id !== undefined ? { client_id } : {}),
              }),
        }).then(toJsonOrRaise)
    }

    static async getMailbyId(getAccessToken: () => Promise<string>, mail_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/mail/${mail_id}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        }).then(toJsonOrRaise)
    }

    static async editMail(getAccessToken: () => Promise<string>, mail_id?: number, name?: string, subject?: string, body?: string) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/mail/${mail_id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name: name,
                subject: subject,
                body: body
            })
        }).then(toJsonOrRaise)
    }

    static async deleteMail(getAccessToken: () => Promise<string>, mail_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/mail/${mail_id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        })
    }

    static async getSMS(getAccessToken: () => Promise<string>, client_id?: number) {
        
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/sms/?client_id=${client_id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          }
        }).then(toJsonOrRaise);
    }

    static async createSMS(getAccessToken: () => Promise<string>, name?: string, body?: string, client_id?: number) {
        
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/sms/`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name,
                body,
                ...(client_id !== undefined ? { client_id } : {}),
              }),
        }).then(toJsonOrRaise)
    }

    static async getSMSbyId(getAccessToken: () => Promise<string>, sms_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/sms/${sms_id}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        }).then(toJsonOrRaise)
    }

    static async editSMS(getAccessToken: () => Promise<string>, sms_id?: number, name?: string, body?: string) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/sms/${sms_id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                name: name,
                body: body
            })
        }).then(toJsonOrRaise)
    }

    static async deleteSMS(getAccessToken: () => Promise<string>, sms_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/sms/${sms_id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        })
    }

    static async getCall(getAccessToken: () => Promise<string>, client_id?: number) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + `/api/call/?client_id=${client_id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${token}`,
            "X-CSRFToken": getCSRFToken(),
          },
        }).then(toJsonOrRaise);
    }

}
