import { useState } from 'react';
import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import Skeleton from '@mui/joy/Skeleton';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import BasicAlert from '../../Alert';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import IconButton from '@mui/joy/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PlanificationEvents from '../../../services/Drawflow';
import DeleteConditionModal from '../../ActionModals/DeleteConditionModal';
import { AddRounded, ReplayRounded } from '@mui/icons-material';
import AddRetryModal from '../../ActionModals/AddRetryModal';
import { timeForm } from '../../../interfaces';
import { formatSendTime } from '../../../utils';
import { useTheme } from '@mui/joy/styles';
import { useAuth0 } from '@auth0/auth0-react';

type MenuTimeDelayProps = {
    planStepId: number | undefined;
    stepData: { operation: string, target: string, field: string, value: string, timedeltas: string, n_attempts: number };
    onClose?: () => void;
    onUpdated?: (response: any) => void;
    onDeleted?: (branch?: number) => void;
};

export default function MenuRetry(props: MenuTimeDelayProps) {

    const theme = useTheme()
    const { t } = useTranslation()
    const {getAccessTokenSilently} = useAuth0()
    const [loadingSave, setLoadingSave] = useState(false)
    const { planStepId, stepData, onClose, onUpdated, onDeleted } = props
    const [alert, setAlert] = useState({ open: false, severity: '', text: '' });
    const [openRetryModal, setOpenRetryModal] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [conditionData, setConditionData] = useState<{ operation: string, target: string, field: string, value: string, timedeltas: string[], n_attempts: number }>({ operation: stepData.operation, target: stepData.target, field: stepData.field, value: stepData.value, timedeltas: stepData.timedeltas.split(';'), n_attempts: stepData.n_attempts })

    const handleCloseAlert = () => {
        setAlert({ open: false, severity: '', text: '' });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name) {
            setConditionData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSelectChange = (
        key: 'target' | 'operation' | 'field',
        newValue: string | null
    ) => {
        if (newValue !== null) {
            setConditionData((prevData) => ({
                ...prevData,
                [key]: newValue,
            }));
        }
        if (key == 'target') {
            setConditionData((prevData) => ({
                ...prevData,
                ['field']: '',
            }));

        }
    };

    const saveItem = async () => {
        setLoadingSave(true)
        const response = await PlanificationEvents.editPlanStep(getAccessTokenSilently, planStepId!, {operation: conditionData.operation, target: conditionData.target, field: conditionData.field, value: conditionData.value, timedeltas: conditionData.timedeltas, n_attempts: conditionData.n_attempts, step_type: 'retry' })
        setLoadingSave(false)
        onUpdated!(response)
        onClose!()
    }

    const saveRetry = async (timeForm: timeForm) => {
        const newTimeDelta = formatSendTime(timeForm);
        const n_attempts = conditionData.n_attempts + 1;
        let currentTimedeltas: string[];
        if (Array.isArray(conditionData.timedeltas)) {
            currentTimedeltas = conditionData.timedeltas;
        } else if (typeof conditionData.timedeltas === 'string') {
            currentTimedeltas = [conditionData.timedeltas]
        } else {
            currentTimedeltas = [];
        }
        //solo añadir a conditiondata, no deberiamos hacer la request hasta el save
        const timedeltas = [...currentTimedeltas, newTimeDelta];
        setConditionData({
            ...conditionData,
            timedeltas: timedeltas,
            n_attempts: n_attempts
        });
        setOpenRetryModal(false)
    }

    const handleOpenDeleteModal = () => {
        setDeleteOpen(true)
    }

    const handleCloseDeleteModal = () => {
        setDeleteOpen(false)
    }


    const handleDeleteTimedelta = (index: number) => {
        if (Array.isArray(conditionData.timedeltas)) {
            const newTimedeltas = conditionData.timedeltas.filter((_, i) => i !== index);
            setConditionData({ ...conditionData, timedeltas: newTimedeltas });
        } else {
            setConditionData({ ...conditionData, timedeltas: [""] });
        }
    };

    const deleteItem = (_deleted: boolean, branch: number) => {
        setDeleteOpen(false)
        onDeleted!(branch)
    }


    return (
        <>
            <DialogTitle>{t("Configuración")}</DialogTitle>
            <ModalClose />
            <Divider />
            <DialogContent sx={{ gap: 2 }}>
                <FormLabel sx={{ typography: 'title-md' }}>{t("Seleccione una condición")}:</FormLabel>
                <FormControl orientation="horizontal">
                    <Box sx={{ flex: 1, pr: 1 }}>
                        {conditionData ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <FormLabel>{t("Elemento")}</FormLabel>
                                <Select placeholder={t("Elige una opción…")} sx={{ width: '100%' }} onChange={(_event, newValue) => handleSelectChange('target', newValue)} value={conditionData.target}>
                                    <Option value="parent">Evento</Option>
                                    <Option value="debtor">Deudor</Option>
                                </Select>
                                <FormLabel sx={{ marginTop: 2 }}>{t("Campo")}</FormLabel>
                                <Select
                                    placeholder={t("Elige una opción…")}
                                    sx={{ width: '100%' }}
                                    onChange={(_event, newValue) => handleSelectChange('field', newValue)}
                                    value={conditionData.field}
                                    disabled={conditionData.target !== 'debtor' && conditionData.target !== 'parent'}
                                >
                                    {conditionData.target === 'debtor' && (
                                        <>
                                             <Option value="name">{t('Nombre')}</Option>
                                            <Option value="surname_1">{t('Primer apellido')}</Option>
                                            <Option value="surname_2">{t('Segundo apellido')}</Option>
                                            <Option value="mail">{t('Mail')}</Option>
                                            <Option value="phone">{t('Teléfono')}</Option>
                                            <Option value="city">{t('Ciudad')}</Option>
                                            <Option value="Postcode">{t('Código postal')}</Option>
                                            <Option value="amount">{t('Importe')}</Option>
                                            <Option value="paid_amount">{t('Importe pagado')}</Option>
                                        </>
                                    )}
                                    {conditionData.target === 'parent' && (
                                        <Option value="status">Status</Option>)
                                    }
                                </Select>
                                <FormLabel sx={{ marginTop: 2 }}>{t("Operación")}</FormLabel>
                                <Select placeholder={t("Elige una opción…")} sx={{ width: '100%' }} onChange={(_event, newValue) => handleSelectChange('operation', newValue)} value={conditionData.operation}>
                                    <Option value="equals">{t("Es igual a")}</Option>
                                    <Option value="not-equals">{t("Es distinto a")}</Option>
                                </Select>
                                <FormLabel sx={{ marginTop: 2 }}>{t("Value")}</FormLabel>
                                <Input
                                    sx={{ width: '100%' }}
                                    required
                                    type="string"
                                    name="value"
                                    id="outlined-required"
                                    value={conditionData.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                />
                            </Box>
                        ) : (
                            <Skeleton variant="rectangular" width={343} height="1em" />
                        )}
                        <Box sx={{ display: 'flex', mt: 3, flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                            <FormLabel sx={{ typography: 'title-md' }}>{t("Reintentos")}:</FormLabel>
                            <Box sx={{ flex: 1, mt: 1, width: '100%' }}>
                                <Box>

                                </Box>
                                {Array.isArray(conditionData.timedeltas) && (
                                    <List sx={{ maxWidth: '100%' }}>
                                        {conditionData.timedeltas.map((timedelta, index) => (
                                            <ListItem variant="outlined" color="primary" key={index} sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', borderRadius: theme.radius.sm, borderColor: theme.vars.palette.primary }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {index + 1}º
                                                    <ReplayRounded />
                                                </Box>
                                                <Box>
                                                   {t('Reintentar después de ')} {timedelta}
                                                </Box>
                                                <IconButton
                                                    size="sm"
                                                    variant="plain"
                                                    color="danger"
                                                    onClick={() => handleDeleteTimedelta(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}

                                <Button sx={{ width: '100%', }} variant="outlined" startDecorator={<AddRounded />} onClick={() => setOpenRetryModal(true)}>
                                    {t("Añadir reintento")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </FormControl>
                <BasicAlert
                    open={alert.open}
                    severity={alert.severity}
                    text={alert.text}
                    onCloseAlert={handleCloseAlert}
                />
            </DialogContent >
            <Divider sx={{ mt: 'auto' }} />
            <Stack
                direction="row"
                justifyContent="space-between"
                useFlexGap
                spacing={1}
            >
                <Button onClick={() => saveItem()} variant="solid" loading={loadingSave}>{t("Guardar")}</Button>
                <Button onClick={() => handleOpenDeleteModal()} variant="plain">{t("Eliminar")}</Button>
            </Stack>
            <DeleteConditionModal
                isOpen={deleteOpen}
                onDelete={(deleted, branch) => deleteItem(deleted, branch)}
                onClose={() => handleCloseDeleteModal()}
            />
            <AddRetryModal
                open={openRetryModal}
                onClose={() => setOpenRetryModal(false)}
                onSubmit={saveRetry}
            />

        </>
    )
}
