//
//
//  Api
//
//

import { User } from "../interfaces";
import { getCSRFToken } from "../utils";


export class RequestStatusFailure extends Error {

    response: any

    constructor(response: any) {
        super()
        this.response = response
    }

}

export class AuthenticationError extends Error {}

import {toJsonOrRaise, raiseForStatus} from "../utils";

export default class Api {


    static login(username: string, password: string) {
        return fetch(import.meta.env.VITE_API_URL + "/api/login", {
            method: "POST",
            body: JSON.stringify({username, password}),
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken()
            }
        }).then(toJsonOrRaise)
    }


    static async token(username: string, password: string) {
        return fetch(import.meta.env.VITE_API_URL + "/api/token", {
            method: "POST",
            body: JSON.stringify({username, password}),
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCSRFToken()
            }
        }).then(toJsonOrRaise)
    }


    static async getUser(getAccessToken: () => Promise<string>): Promise<User | null> {
        
        const token = await getAccessToken()
        if (token == null) {
            return null
        }

        return fetch(import.meta.env.VITE_API_URL + "/api/user/", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        }).then(toJsonOrRaise)
    }

    static async editUser({getAccessToken, username = undefined, email = undefined}: {getAccessToken?: any, username?: string, email?: string}) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + "/api/user/", {
            method: "PATCH",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                username: username,
                email: email
            })
        }).then(toJsonOrRaise)
    }

    static async replacePassword(getAccessToken: () => Promise<string>, oldPassword: string, newPassword: string) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + "/api/user/password", {
            method: "PUT",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({
                old_password: oldPassword,
                new_password: newPassword
            })
        }).then(toJsonOrRaise)
    }

    static async createVoiceCredential(getAccessToken: () => Promise<string>, audio: Blob) {
        const token = await getAccessToken()
        const formData = new FormData()
        formData.append("audio", audio, "audio.wav")

        return fetch(import.meta.env.VITE_API_URL + "/api/user/voice-biometry", {
            method: "POST",
            credentials: "include",
            body: formData,
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            }
        }).then(raiseForStatus)
    }

    static async deleteVoiceCredential(getAccessToken: () => Promise<string>) {
        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + "/api/user/voice-biometry", {       
            method: "DELETE",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
                "X-CSRFToken": getCSRFToken()
            },
        })
    }


    static async getClients(getAccessToken: () => Promise<string>) {

        const token = await getAccessToken()
        return fetch(import.meta.env.VITE_API_URL + "/api/client/", {
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        }).then(toJsonOrRaise)
    }

    static async getPermission(getAccessToken: () => Promise<string>, target: string, operation: string) {
        let url = import.meta.env.VITE_API_URL + "/api/permission/?target="+target+"&operation="+operation;
        const token = await getAccessToken()
        console.assert(token != null)
    
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        }
    
        const response = await fetch(url, options)
        return toJsonOrRaise(response)
    }
}
