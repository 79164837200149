import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import colors from '@mui/joy/colors';

import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CampaignIcon from '@mui/icons-material/Campaign';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendIcon from '@mui/icons-material/Send';
import PeopleIcon from '@mui/icons-material/People';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

import BaseView from './BaseView';
import CollapsibleTable from '../components/CollapsibleTable';
import { HeadCell, Data } from '../components/CollapsibleTable';
import CampaignCalendar from '../components/CampaignCalendar';
import DataCard from '../components/DataCard';
import Breadcrumbs from '../components/Header/Breadcrumbs'
import { useAuth0 } from "@auth0/auth0-react";



const columns: HeadCell[] = [
    {
        id: 'timestamp',
        dataType: 'date',
        disablePadding: true,
        label: 'Fecha',
    },
    {
        id: 'action_name',
        dataType: 'string',
        disablePadding: false,
        label: 'Evento',
    },
    {
        id: 'action_type',
        dataType: 'string',
        disablePadding: false,
        label: 'Tipo',
    },
];


interface CampaignHeaderProps {
    campaign: Record<string, any>;
    campaignInfo: Record<string, any>;
}

function CampaignHeader(props: CampaignHeaderProps) {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();

    const { campaign, campaignInfo } = props;
    const [planification, setPlanification] = useState<Record<string, any>>({});

    async function getCampaignInfo() {
        if (campaign.planification_id) {
            const token = await getAccessTokenSilently();
            let events_url = import.meta.env.VITE_API_URL + '/api/planification/'+campaign.planification_id

            const options = {
                method: 'GET',
                headers: {
                    'Authorization':`Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }

            fetch(events_url, options)
                .then((response) => response.json())
                .then((data) => {
                    setPlanification(data);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    }

    useEffect(() => {
        getCampaignInfo();
    }, [campaign]);

    if (campaign.start_date !== undefined && campaignInfo.end_date !== undefined) {
        let start_date = new Date(campaign.start_date);
        let end_date = new Date(campaignInfo.end_date);

        return (
            <Grid container spacing={2}>
                <Grid xs={12} md={3} sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <DataCard 
                        title={t("Campaña")} 
                        content={campaign.name}
                        width={250}            
                    >
                        <CampaignIcon 
                            fontSize="large"
                            color="primary"
                        />
                    </DataCard>
                </Grid>
                
                <Grid xs={12} md={3} sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <DataCard 
                        title={t("Estrategia")} 
                        content={planification.name}
                        width={250}
                    >
                        <CalendarMonthIcon 
                            fontSize="large"
                            color="secondary"
                        />
                    </DataCard>
                </Grid>

                <Grid xs={12} md={3} sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <DataCard 
                        title={t("Fecha de inicio")} 
                        content={start_date.toLocaleDateString("es-ES", {timeZone: campaign["timezone"]})}
                        width={250}
                    >
                        <PlayCircleFilledWhiteIcon 
                            fontSize="large"
                            sx={{ color: colors["green"][500] }}
                        />
                    </DataCard>
                </Grid>

                <Grid xs={12} md={3} sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <DataCard 
                        title={t("Fecha de fin")} 
                        content={end_date.toLocaleDateString("es-ES", {timeZone: campaign["timezone"]})}
                        width={250}
                    >
                        <StopCircleIcon 
                            fontSize="large"
                            sx={{ color: colors["red"][500] }}
                        />
                    </DataCard>
                </Grid>
            </Grid>
        )
    } else {
        return (<></>)
    }
}


interface CampaignInfoProps {
    campaignInfo: Record<string, any>;
}

function CampaignInfo(props: CampaignInfoProps) {
    const { t } = useTranslation();
    const { campaignInfo } = props;

    return (
        <Grid container spacing={2}>
            <Grid xs={12} md={4}>
                <DataCard title={t("Eventos planeados")} content={campaignInfo.num_events} width={350}>
                        <ScheduleIcon fontSize="large" color="primary"/>
                </DataCard>
            </Grid>
            <Grid xs={12} md={4}>
                <DataCard title={t("Deudores")} content={campaignInfo.num_debtors} width={350}>
                        <PeopleIcon fontSize="large" color="primary"/>
                </DataCard>
            </Grid>
            <Grid xs={12} md={4}>
                <DataCard title={t("Cantidad pendiente")} content={campaignInfo.pending_amount} width={350}>
                        <AttachMoneyIcon fontSize="large" color="primary"/>
                </DataCard>
            </Grid>

            <Grid xs={12} md={4}>
                <DataCard title={t("Eventos envíados")} content={campaignInfo.sent_events} width={350}>
                        <SendIcon fontSize="large" color="primary"/>
                </DataCard>
            </Grid>
            <Grid xs={12} md={4}>
                <DataCard title={t("Deudas recolectadas")} content={campaignInfo.collected_debts} width={350}>
                        <ChecklistIcon fontSize="large" color="primary"/>
                </DataCard>
            </Grid>
            <Grid xs={12} md={4}>
                <DataCard title={t("Cantidad recolectada")} content={campaignInfo.collected_amount} width={350}>
                        <PriceCheckIcon fontSize="large" color="primary"/>
                </DataCard>
            </Grid>
        </Grid>
    )
}
  

export default function CampaignDetail() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();

    const { id } = useParams();
    const [campaign, setCampaign] = useState<Record<string, any>>({});
    const [campaignInfo, setCampaignInfo] = useState({});
    const [events, setEvents] = useState([]);

    async function getCampaignDetails() {
        const accessToken = await getAccessTokenSilently();

        function convertDates(row: Record<keyof Data, any>) {
            row.timestamp = new Date(row.timestamp);
        }

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        }

        let campaign_url = import.meta.env.VITE_API_URL + '/api/campaign/'+id

        fetch(campaign_url, options)
            .then((response) => response.json())
            .then((data) => {
                setCampaign(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

        let campaign_info_url = import.meta.env.VITE_API_URL + '/api/campaign/'+id+'/info'
        let timezone = "Europe/Madrid"
        let strategy = null
        fetch(campaign_info_url, options)
            .then((response) => response.json())
            .then((data) => {
                setCampaignInfo(data);
                timezone = data.timezone
                strategy = data.planification_id
                const today = new Date()
                const dateStr =  today.toISOString().slice(0, 10);
                
                let events_url = import.meta.env.VITE_API_URL + '/api/planification/'+strategy+'/preview?start_date='+dateStr+"&timezone="+timezone
        
                fetch(events_url, options)
                    .then((response) => response.json())
                    .then((data) => {
                        {data.map(convertDates)}
                        setEvents(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
                
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    useEffect(() => {
        //const searchParams = new URLSearchParams(location.search);
        //const idFromQueryParam = searchParams.get('id');
        //setId(idFromQueryParam ? Number(idFromQueryParam) : undefined);

        getCampaignDetails();
    }, [location.search]);

    return (
        <BaseView view="/campaign/">
            <Breadcrumbs elements={[
                {"name": t("Campañas"), "href": "/campaign/"},
                {"name": campaign.name}
            ]}/>
            <div>
                <br />
                <br />
                <Box component="fieldset" sx={{ border: 0, borderColor: 'grey.300', borderRadius: '16px' }}>
                    <legend>
                    <Typography level="h4" gutterBottom>
                        {t("Información")}
                    </Typography>
                    </legend>
                    <CampaignHeader campaign={campaign} campaignInfo={campaignInfo}></CampaignHeader>
                </Box>

                <br />
                <br />
                <Box component="fieldset" sx={{ border: 0, borderColor: 'grey.300', borderRadius: '16px' }}>
                    <legend>
                    <Typography level="h4" gutterBottom>
                        {t("Calendario")}
                    </Typography>
                    </legend>
                    <CampaignCalendar events={events} timezone={campaign["timezone"]} />
                </Box>

                <br />
                <br />
                <Box component="fieldset" sx={{ border: 0, borderColor: 'grey.300', borderRadius: '16px' }}>
                    <legend>
                    <Typography level="h4" gutterBottom>
                        {t("Estadísticas")}
                    </Typography>
                    </legend>
                    <CampaignInfo campaignInfo={campaignInfo}></CampaignInfo>
                </Box>

                <br />
                <br />

                <Box component="fieldset" sx={{ border: 0, borderColor: 'grey.300', borderRadius: '16px' }}>
                    <legend>
                    <Typography level="h4" gutterBottom>
                        {t("Histórico de eventos")}
                    </Typography>
                    </legend>
                    <CollapsibleTable rows={events} columns={columns} timezone={campaign["timezone"]} />
                </Box>

                <br />
                <br />
            </div>  
        </BaseView>
    )
  }