import { useTranslation } from "react-i18next";

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { ReplayRounded } from "@mui/icons-material";
import { Divider } from "@mui/joy";


export default function RetryNodeComponent() {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <Box sx={{ mr: 1, display: 'flex' }}>
                    <ReplayRounded/>
                </Box>
                <Divider orientation="vertical" />
                <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>
                    {t('Reintento')}
                </Typography>
            </Box>
        </>
    )
}