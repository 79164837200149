import React from 'react'
import ReactDOM from 'react-dom/client'
import "./services/i18n";
import App from './App.tsx'
import './index.css'
import "maia-login"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter';
import {BrowserRouter} from "react-router-dom";




ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>
)
