import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const i18nInstance = i18next.createInstance();
i18nInstance
    .use(initReactI18next)
    .use(HttpApi) // Registering the back-end plugin
    .use(LanguageDetector)
    .init({
        detection: {
            convertDetectedLanguage: (lng) => lng.split('-')[0]
        },

        backend: {
            loadPath: `${import.meta.env.VITE_STATIC_PATH}/locales/{{lng}}/{{ns}}.json`
        },

        ns: ["translation"],
        defaultNS: "translation",

        interpolation: {
         escapeValue: false,
        },
    });

export default i18next;