import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useNavigate } from 'react-router-dom';


export interface NoTemplateWarningModalProps {
    isOpen: boolean;
    template: string
    onClose: () => void;
}



export default function NoTemplateWarningModal(props: NoTemplateWarningModalProps) {
    const { t } = useTranslation();

    const navigate = useNavigate()
    const { isOpen, template, onClose } = props;
    const [open, setOpen] = React.useState(isOpen);


    const handleClose = () => {
        setOpen(false)
        onClose()
    };
    const handleCreateTemplate = () =>  {
        navigate(`/${template}`)
        
    }

    React.useEffect(() => {
        setOpen(isOpen)
    }, [isOpen]);


  return (
      <Modal open={open} onClose={() => setOpen(open)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {t("No hay ninguna plantilla creada")}
          </DialogTitle>
          <Divider />
          <DialogContent>
            {t("¿Quieres crear una plantilla?")}
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="primary" onClick={() => handleCreateTemplate()}>
              {t("Crear plantilla")}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => handleClose()}>
              {t("Cancelar")}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
  );
}