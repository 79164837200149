import { Handle, Position } from 'reactflow';
import { FC } from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow';
import { Box, Typography } from '@mui/joy';

export const AddingNodeType = ({ data }: { data: { label: string } }) => {
    return (
        <>
            <div>{data.label}</div>
            <Handle type="target" position={Position.Top} />
        </>
    );
};

export const HiddenNodeType = ({ data }: { data: { label: string } }) => {
    return (
        <>
            <div>{data.label}</div>
            <Handle type="target" position={Position.Bottom} />
        </>
    );
};

export const AddEdge: FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer> 
                <Box
                    sx={{
                        position: 'absolute',
                        z:999,
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        background: (theme) => theme.palette.primary.solidBg,
                        borderRadius: 100,
                        fontSize: 4,
                        color:'white',
                        pointerEvents:'all',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.primary.solidHoverBg,
                          },
                    }}
                    className="add-label"
                >
                    
                </Box>
            </EdgeLabelRenderer>
        </>
    );
};


export const NoEdge: FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                <Box
                    sx={{
                        position: 'absolute',
                        z:999,
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        background: (theme) => theme.palette.danger.solidBg,
                        borderRadius: 5,
                        fontSize: 4,
                        color:'white',
                        pointerEvents:'all',
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.danger.solidHoverBg,
                          },
                    }}
                    className="add-label"
                >
                     <Typography sx={{color:'white', pl:1, pr:1}}>
                        No
                    </Typography>
                </Box>
            </EdgeLabelRenderer>
        </>
    );
};

export const YesEdge: FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                <Box
                    sx={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        background: (theme) => theme.palette.success.solidBg,
                        borderRadius: 5,
                        fontSize: 4,
                        color:'white',
                        pointerEvents:'all',
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.success.solidHoverBg,
                          },
                    }}
                    className="add-label"
                >
                    <Typography sx={{color:'white', pl:1, pr:1}}>
                        Yes
                    </Typography>
                </Box>
            </EdgeLabelRenderer>
        </>
    );
};


export type FitViewOptions = {
    padding?: number;
    includeHiddenNodes?: boolean;
    minZoom?: number;
    maxZoom?: number;
    duration?: number;
    nodes?: (Partial<Node> & { id: string })[];
};

