import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import Typography  from "@mui/joy/Typography";
import { PhoneRounded } from '@mui/icons-material';
import { Divider } from "@mui/joy";

export default function CallNodeComponent() {

    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <Box sx={{ mr: 1, display: 'flex' }}>
                <PhoneRounded sx={{color: '#43a047'}}/>
                </Box>
                <Divider orientation="vertical" />
                <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>
                    {t('Llamada')}
                </Typography>
            </Box>
        </>
    )
}