import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

import Template from '../../services/Template';
import Box from '@mui/joy/Box';
import CssBaseline from '@mui/joy/CssBaseline';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, FormLabel, Textarea, Typography } from '@mui/joy';
import Breadcrumbs from '../../components/Header/Breadcrumbs';
import BasicAlert from '../../components/Alert';
import PersonIcon from '@mui/icons-material/Person';
import MoneyIcon from '@mui/icons-material/Money';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import PhoneContainer from '../../components/PhoneContainer/PhoneContainer';
import BaseView from '../BaseView';
import SettingsModal from '../../components/ActionModals/SettingsModal';
import { IconButton } from '@mui/joy';
import { useAuth0 } from '@auth0/auth0-react';


const drawerWidth = 240;

export default function SMSEdit() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const { id } = useParams();
    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const [SMSId, setSMSId] = useState<number | undefined>(undefined);
    const [nameTemplate, setNameTemplate] = useState('');
    const [successAlertStatus, setSuccessAlertStatus] = useState(false);
    const [warningAlertStatus, setWarningAlertStatus] = useState(true);
    const [messages, setMessages] = useState([''])
    const [body, setBody] = useState('');
    const [characterCount, setCharacterCount] = useState(0)
    const bodyRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [errorOnUpdate, setErrorOnUpdate] = useState('')


    const saveTemplate = async () => {

        await Template.editSMS(getAccessTokenSilently, SMSId, nameTemplate, body)
            .then(() => {
                setSuccessAlertStatus(true)
            })
    }

    const updateName = async (name:string) => {

        await Template.editMail(getAccessTokenSilently, SMSId, nameTemplate)
            .then(() => {
                setNameTemplate(name)
                setOpenSettingsModal(false)
            }).catch((err) => {
                setErrorOnUpdate(err)
            })
    }

    const handleAddText = (id_name: string) => {
        const newValue = `{{${id_name}}}`;
        const currentSubjectValue = bodyRef.current?.value || '';
        const cursorPosition = bodyRef.current?.selectionStart || 0;
        const updatedValueArray = currentSubjectValue.split('');
        updatedValueArray.splice(cursorPosition, 0, newValue);
        const updatedValue = updatedValueArray.join('');
        const newPosition = cursorPosition + newValue.length;
        setBody(updatedValue);
        setTimeout(() => {
            bodyRef.current?.setSelectionRange(newPosition, newPosition);
            bodyRef.current?.focus();
        }, 0);
    };

    React.useEffect(() => {
        setSMSId(id ? Number(id) : undefined);
    }, [id]);


    React.useEffect(() => {
        if (id !== undefined) {
            const fetchData = async () => {
                try {
                    const SMSId = id ? Number(id) : undefined;
                    const data = await Template.getSMSbyId(getAccessTokenSilently, SMSId);
                    setNameTemplate(data.name);
                    setBody(data.body);
                } catch (err) {
                    console.error(err);
                }
            };
            fetchData();
        } else {
            setSMSId(undefined);
        }
    }, [SMSId]);


    React.useEffect(() => {
        const messageList = [];
        let remainingBody = body;

        while (remainingBody.length > 0) {
            let endIndex = 160;
            let message = remainingBody.substring(0, endIndex);
            const lastIndexOfOpenBraces = message.lastIndexOf("{{");
            const lastIndexOfCloseBraces = message.lastIndexOf("}}");

            if (lastIndexOfOpenBraces > lastIndexOfCloseBraces) {
                endIndex = lastIndexOfOpenBraces;
                message = remainingBody.substring(0, endIndex);
            }

            remainingBody = remainingBody.substring(endIndex);

            messageList.push(message);
        }

        setCharacterCount(body.replace(/{{.*?}}/g, '').length);
        setMessages(messageList);
    }, [body]);






    return (
        <BaseView view="/sms/">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Breadcrumbs elements={[
                    { "name": "SMS", "href": "/sms/" },
                    { "name": nameTemplate }
                ]} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2">{nameTemplate}</Typography>
                <IconButton aria-label="settings" onClick={() => setOpenSettingsModal(true)}>
                    <SettingsRoundedIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CssBaseline />
                <Box
                    component="main"
                    sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        padding: 5,
                        borderRadius: '4px',
                        width: '50%'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ButtonGroup aria-label="button group">
                                {[{ 'id': 'name', 'name': t('Nombre'), 'icon': <PersonIcon /> }, { 'id': 'surname', 'name': t('Apellido'), 'icon': <PersonIcon /> }, { 'id': 'amount', 'name': t('Cantidad'), 'icon': <MoneyIcon /> }].map((item) => (
                                    <Button onClick={() => handleAddText(item.id)} startDecorator={item.icon}> {item.name}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Box>
                        <FormLabel sx={{ margin: 2 }} htmlFor="body">{t("Cuerpo")}</FormLabel>
                        <Textarea
                            id="body"
                            placeholder={t("Escriba el cuerpo") + "..."}
                            value={body}
                            minRows={4}
                            maxRows={4}
                            onChange={(e) => setBody(e.target.value)}
                            slotProps={{ textarea: { ref: bodyRef } }}
                        />
                        <Box sx={{ marginTop: 1 }}>
                            <BasicAlert
                                open={successAlertStatus}
                                severity={'success'}
                                text={t("Plantilla guardada correctamente")}
                                onCloseAlert={() => setSuccessAlertStatus(false)}
                            />
                        </Box>
                        <Box sx={{ marginTop: 1 }}>
                            <BasicAlert
                                open={warningAlertStatus}
                                severity={'warning'}
                                text={t("El número de caracteres es orientativo: las variables tienen una longitud indeterminada.")}
                                onCloseAlert={() => setWarningAlertStatus(false)}
                            />
                        </Box>
                        <Box>
                            {t("Número de caracteres")}: {characterCount} ({t("Max por mensaje")}: 160)
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', paddingTop: 3 }}>
                            <Button onClick={saveTemplate}>{t("Guardar")}</Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '100%',
                        maxWidth: '100%',
                        flex: '1 1 40%',
                        padding: 5,
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        maxHeight: '100%',
                        overflowY: 'auto',
                    }}>
                        <Box sx={{
                            margin: 2,
                        }}>
                            <b>{t("Previsualización")}:</b>
                        </Box>
                        <PhoneContainer
                            messages={messages}
                        />
                    </Box>
                </Box>
            </Box>
            {nameTemplate && (
            <SettingsModal
                open={openSettingsModal}
                defaultValue={nameTemplate}
                onClose={() => setOpenSettingsModal(false)}
                onSubmit={updateName}
                error={errorOnUpdate}
            />
            )}
        </BaseView>
    );
}