import { useState, useEffect } from "react";

import Drawer from '@mui/joy/Drawer';
import Sheet from '@mui/joy/Sheet';
import PlanificationEvents from '../services/Drawflow';
import MenuAction from "../components/Flow/Menus/MenuAction";
import MenuTimeDelay from "../components/Flow/Menus/MenuTimeDelay";
import MenuCondition from "../components/Flow/Menus/MenuCondition";
import MenuRetry from "../components/Flow/Menus/MenuRetry";
import MenuStart from "../components/Flow/Menus/MenuStart";
import { Skeleton } from "@mui/joy";
import { useAuth0 } from "@auth0/auth0-react";

type Anchor = 'right';

interface DrawflowMenuProps {
  open: boolean;
  onClose: () => void;
  planStepId: number | undefined;
  onUpdated?: (response: any) => void;
  onDeleted?: (branch?: number) => void;
}


export default function DrawflowMenu({ open, onClose, planStepId, onUpdated, onDeleted }: DrawflowMenuProps) {

  const anchor: Anchor = 'right';
  const [state, setState] = useState({ [anchor]: open });
  const { getAccessTokenSilently } = useAuth0()
  const [stepType, setStepType] = useState('');
  const [stepData, setStepData] = useState<any>({})
  const [actionId, setActionId] = useState(undefined);
  const [contentTypeId, setContentTypeId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [timeForm, setTimeForm] = useState<{ days: number, hours: number, minutes: number }>({
    days: 0,
    hours: 0,
    minutes: 0
  })

  const components: { [key: string]: JSX.Element } = {
    action: (
      <MenuAction
        planStepId={planStepId}
        contentTypeId={contentTypeId}
        defaultActionId={actionId}
        onClose={onClose}
        onUpdated={onUpdated}
        onDeleted={onDeleted}
      />
    ),
    delay: (
      <MenuTimeDelay
        planStepId={planStepId}
        defaultTimeForm={timeForm}
        onClose={onClose}
        onUpdated={onUpdated}
        onDeleted={onDeleted}
      />
    ),
    condition: (
      <MenuCondition
        planStepId={planStepId}
        stepData={stepData}
        onClose={onClose}
        onDeleted={onDeleted}
      />

    ),
    retry: (
      <MenuRetry
        planStepId={planStepId}
        stepData={stepData}
        onClose={onClose}
        onUpdated={onUpdated}
        onDeleted={onDeleted}
      />

    ),
    start: (
      <MenuStart
        planStepId={planStepId}
        defaultTimeForm={timeForm}
        onClose={onClose}
      />
    )
  };

  const selectedComponent = components[stepType] || null;


  useEffect(() => {
    setActionId(undefined)
    setState({ [anchor]: open })
    if (open !== null && planStepId !== 0 && planStepId !== undefined) {
      fetchData();
      return;
    }

  }, [open, planStepId]);

  const fetchData = async () => {
    setLoading(true)
    const data = await PlanificationEvents.getPlanStepById(getAccessTokenSilently, planStepId as number);
    if (!data.parent_id) {
      setStepType('start')
    } else {
      setStepType(data.step_type)
    }
    setStepData(data.step_data)
    if (data.step_type == 'action') {
      setContentTypeId(data.step_data.content_type_id)
      setActionId(data.step_data.object_id)
    }
    if (data.step_type == 'condition') {
      setContentTypeId(data.step_data.content_type_id)
      setActionId(data.step_data.object_id)
    }
    if (data.step_type == 'delay') {
      const timeStringParts: string[] = data.step_data.timedelta.split(/[:,]/).map((part: string) => part.trim());
      let days = 0;
      let hours = 0;
      let minutes = 0;

      if (timeStringParts.length >= 2) {
        if (timeStringParts[0].includes('day')) {
          if (timeStringParts[0].includes('days')) {
            days = parseInt(timeStringParts[0]);
          } else {
            days = 1;
          }
          hours = parseInt(timeStringParts[1]);
          if (timeStringParts.length >= 3) {
            minutes = parseInt(timeStringParts[2]);
          }
        } else {
          hours = parseInt(timeStringParts[0]);
          if (timeStringParts.length >= 2) {
            minutes = parseInt(timeStringParts[1]);
          }
        }
      }
      setTimeForm({ days, hours, minutes });
    }
    setLoading(false)
  };




  return (
    <>
      <Drawer size="md"
        variant="plain"
        slotProps={{
          content: {
            sx: {

              bgcolor: 'transparent',
              p: { md: 3, sm: 0 },
              boxShadow: 'none',
            },
          },
        }} anchor={anchor} open={state[anchor]} onClose={async() => {
          await onClose(); // Call onClose() when the drawer is closed
          setState({ ...state, [anchor]: false });
          
        }}
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
          },
        }}>
        <Sheet
          sx={{
            borderRadius: 'md',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
          }}>
          {loading ? (
            <>
              <Skeleton variant="rectangular" width={'100%'} height="2em" />
              <Skeleton variant="rectangular" width={'100%'} height="4em" />
              <Skeleton level="body-md" variant="text" width="92%" />
              <Skeleton level="body-md" variant="text" width="99%" />
              <Skeleton level="body-md" variant="text" width="96%" />
            </>

          ) : selectedComponent
          }
        </Sheet>
      </Drawer>
    </>
  );
}

