import { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from "react-i18next";


import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import Skeleton from '@mui/joy/Skeleton';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import BasicAlert from '../../Alert';


import PlanificationEvents from '../../../services/Drawflow';
import DeleteModal from '../../ActionModals/DeleteModal';
import { formatSendTime } from '../../../utils';
import { timeForm } from '../../../interfaces';
import { useAuth0 } from '@auth0/auth0-react';

type MenuTimeDelayProps = {
    planStepId: number | undefined;
    defaultTimeForm: timeForm;
    onClose: () => void;
    onUpdated?: (response: any) => void;
    onDeleted?: () => void;

};
export default function MenuTimeDelay(props: MenuTimeDelayProps) {
    const { t } = useTranslation()
    const { getAccessTokenSilently } = useAuth0()
    const [loadingSave, setLoadingSave] = useState(false)
    const { planStepId, defaultTimeForm, onClose, onUpdated, onDeleted } = props
    const [alert, setAlert] = useState({ open: false, severity: '', text: '' });
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [timeForm, setTimeForm] = useState<timeForm>(defaultTimeForm)


    useEffect(() => {
          setTimeForm(defaultTimeForm);
      }, [planStepId, defaultTimeForm]);


    const handleCloseAlert = () => {
        setAlert({ open: false, severity: '', text: '' });
    }


    const handleSendTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 3) {
            return
        }
        if (parseInt(event.target.value) < 0) {
            return
        }

        setTimeForm({ ...timeForm, [event.target.name]: parseInt(event.target.value) });
    };

    const saveItem = async () => {
        const sendTime = formatSendTime(timeForm)
        setLoadingSave(true)
        const response = await PlanificationEvents.editPlanStep(getAccessTokenSilently, planStepId!, {timedelta: sendTime, step_type: 'delay' })
        setLoadingSave(false)
        onUpdated!(response)
        onClose()
    }

    const handleOpenDeleteModal = () => {
        setDeleteOpen(true)
    }

    const handleCloseDeleteModal = () => {
        setDeleteOpen(false)
    }

    const deleteItem = () => {
        setDeleteOpen(false)
        onDeleted!()
    }


    return (
        <>
            <DialogTitle>{t("Configuración")}</DialogTitle>
            <ModalClose />
            <Divider />
            <DialogContent sx={{ gap: 2 }}>
                <FormLabel sx={{ typography: 'title-md' }}>{t("Seleccione un rango de tiempo")}:</FormLabel>
                <FormControl orientation="horizontal">
                    <Box sx={{ flex: 1, pr: 1 }}>
                        {timeForm ? (
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <FormLabel>{t("Días")}</FormLabel>
                                <Input
                                    sx={{ maxWidth: 80 }}
                                    required
                                    type="number"
                                    name="days"
                                    id="outlined-required"
                                    value={timeForm.days}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendTimeChange(event)}
                                />
                                <FormLabel sx={{ marginLeft: 2 }}>{t("Horas")}</FormLabel>
                                <Input
                                    sx={{ maxWidth: 80 }}
                                    required
                                    type="number"
                                    name="hours"
                                    id="outlined-required"
                                    value={timeForm.hours}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendTimeChange(event)}

                                />
                                <FormLabel sx={{ marginLeft: 2 }}>{t("Minutos")}</FormLabel>
                                <Input
                                    sx={{ maxWidth: 80 }}
                                    required
                                    type="number"
                                    name="minutes"
                                    id="outlined-required"
                                    value={timeForm.minutes}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendTimeChange(event)}
                                />
                            </Box>
                        ) : (
                            <Skeleton variant="rectangular" width={343} height="1em" />
                        )}
                    </Box>
                </FormControl>
                <BasicAlert
                    open={alert.open}
                    severity={alert.severity}
                    text={alert.text}
                    onCloseAlert={handleCloseAlert}
                />
            </DialogContent >
            <Divider sx={{ mt: 'auto' }} />
            <Stack
                direction="row"
                justifyContent="space-between"
                useFlexGap
                spacing={1}
            >
                <Button onClick={() => saveItem()} loading={loadingSave} variant="solid">{t("Save")}</Button>
                <Button onClick={() => handleOpenDeleteModal()} variant="plain">{t("Delete")}</Button>
            </Stack>
            <DeleteModal
                isOpen={deleteOpen}
                onDelete={() => deleteItem()}
                onClose={() => handleCloseDeleteModal()}
            />
        </>
    )
}
