import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import * as React from 'react';
import Alert from '@mui/joy/Alert';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { ColorPaletteProp } from '@mui/joy/styles';

export interface AlertProps {
  open: boolean;
  severity: string;
  text: string;
  onCloseAlert: () => void
}

export default function BasicAlert(props: AlertProps) {
  const { open, severity, text, onCloseAlert } = props;

  const items: {
    title: string;
    color: ColorPaletteProp;
    icon: React.ReactElement;
  }[] = [
    { title: 'Neutral', color: 'neutral', icon: <InfoIcon /> },
    { title: 'Warning', color: 'warning', icon: <WarningIcon /> },
    { title: 'Error', color: 'danger', icon: <ReportIcon /> },
    { title: 'Success', color: 'success', icon: <CheckCircleIcon /> },
  ];

  const item = items.find((item) => item.title.toLowerCase() === severity.toLowerCase());

  if (!item) {
    
    return null;
  }

  const { title, color, icon } = item;

  return (
    open ? (
      <Alert
        key={title}
        sx={{ alignItems: 'center' }}
        startDecorator={icon}
        variant="soft"
        color={color}
        endDecorator={
          <IconButton onClick={onCloseAlert} variant="soft" color={color}>
            <CloseRoundedIcon />
          </IconButton>
        }
      >
        <Box>
          <Typography level="body-sm" color={color}>
            {text}
          </Typography>
        </Box>
      </Alert>
    ) : null
  )
}
