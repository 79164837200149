import Box from '@mui/material/Box';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

export default function AddingNodeComponent() {

    return (
        <>
            <Box sx={{width:'100%', height:'100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Box>
                    <AddRoundedIcon
                    />
                </Box>
            </Box>
        </>
    )
}