import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import { timeForm } from '../../interfaces';

export interface AddRetryModalProps {
    open: boolean;
    onSubmit: (timeForm: timeForm) => void;
    onClose: () => void;
}


export default function AddRetryModal(props: AddRetryModalProps) {
    const { t } = useTranslation();
   
    const { open, onSubmit, onClose } = props;
    const [timeForm, setTimeForm] = React.useState<timeForm>({days:0, hours:10, minutes: 0})

    const handleSendTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 3) {
            return
        }
        if (parseInt(event.target.value) < 0) {
            return
        }

        setTimeForm({ ...timeForm, [event.target.name]: parseInt(event.target.value) });
    };

    const handleSubmit = () => {
        onSubmit(timeForm);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog>
                <DialogTitle>{t("Añadir un reintento")}</DialogTitle>
                <DialogContent>{t("Personalice el rango de tiempo")}</DialogContent>
                <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Stack spacing={2}>
                    <FormLabel>{t("Días")}</FormLabel>
                                <Input
                                    required
                                    type="number"
                                    name="days"
                                    id="outlined-required"
                                    value={timeForm.days}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendTimeChange(event)}
                                />
                                <FormLabel sx={{ marginLeft: 2 }}>{t("Horas")}</FormLabel>
                                <Input
                                    required
                                    type="number"
                                    name="hours"
                                    id="outlined-required"
                                    value={timeForm.hours}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendTimeChange(event)}

                                />
                                <FormLabel sx={{ marginLeft: 2 }}>{t("Minutos")}</FormLabel>
                                <Input
                                    required
                                    type="number"
                                    name="minutes"
                                    id="outlined-required"
                                    value={timeForm.minutes}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendTimeChange(event)}
                                />
                        <Button type="submit">{t("Crear")}</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal >
    );
}
