import { useTranslation } from "react-i18next";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';





const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

interface UploadFileProps {
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedFile?: string
    error?: boolean
}

export default function UploadFile(props: UploadFileProps) {
  const { t } = useTranslation();

  const { changeHandler, selectedFile, error=false } = props;

  return (
    <FormControl fullWidth error={error} style={{minWidth: 450}}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          {t("Seleccionar archivo")}
          <VisuallyHiddenInput
                type="file"
                onChange={changeHandler}
          />
        </Button>
        
        {selectedFile && <FormHelperText> {selectedFile} </FormHelperText>}
        {error && <FormHelperText> {t("Seleccione un archivo de formato Excel válido")} </FormHelperText>}
    </FormControl>
  );
}