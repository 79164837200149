import * as React from 'react';
import { useTranslation } from "react-i18next";

import Box from '@mui/joy/Box';
import { PieChart } from '@mui/x-charts/PieChart';
import Typography from '@mui/joy/Typography';
import Breadcrumbs from '../components/Header/Breadcrumbs';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot } from '@mui/x-charts/LineChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { AllSeriesType } from '@mui/x-charts/models';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import BaseView from './BaseView';

const options = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
}

export default function Strategies() {

    const { t } = useTranslation();

    const [listValue, setListValue] = React.useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let year = dateObj.getUTCFullYear();
    
    const monthSelected = String(month)
    const yearSelected = String(year)
    const [totalPayment, setTotalPayment] = React.useState('-1')
    
    const series = [
        {
          type: 'bar',
          stack: '',
          yAxisKey: 'payment',
          data: listValue,
        },
      ] as AllSeriesType[];

    function loadData(month:string,year:string){
        let url = import.meta.env.VITE_API_URL + '/api/statistics/'+ year + '-' + month
        fetch(url, options)
            .then((response) => response.json())
            .then((data) => {

                var list_value = []
                for (let key in data['info']) {
                    list_value.push(data['info'][key])
                }
                setListValue(list_value)
                setTotalPayment(data['total_payment'])
            })
            .catch((err) => {
                console.log(err.message);
        });
    }
    return (
        <BaseView view="/strategies/">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs elements={[
                    { "name": t("Estadísticas") },
                ]} />
            </Box>
 
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2"> {t("Estadísticas")} </Typography>
            </Box>
            <Typography> {t("Seleccione el año y mes")} </Typography>

            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                }}
            >

            <Select defaultValue={yearSelected} onChange={(_event, newValue)=>loadData(monthSelected,newValue!)}>
                <Option value="2023">2023</Option>
                <Option value="2024">2024</Option>

            </Select>

            <Select defaultValue={monthSelected} onChange={(_event, newValue)=>loadData(newValue!,yearSelected)}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
                <Option value="11">11</Option>
                <Option value="12">12</Option>
            </Select>

            </Box>

            <Typography level="h4" gutterBottom> {t("Importe recuperado por día")} </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>


    <ChartContainer
      series={series}
      width={500}
      height={400}
      xAxis={[
        {
          id: 'days',
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
          scaleType: 'band',
          valueFormatter: (value) => value.toString(),
        },
      ]}
      yAxis={[
        {
          id: 'payment',
          scaleType: 'linear',
        }
      ]}
    >
      <BarPlot />
      <LinePlot />
      <ChartsXAxis label="Days" position="bottom" axisId="days" />
      <ChartsYAxis label="Payment" position="left" axisId="payment" />
    </ChartContainer>
        {totalPayment != '-1' &&( <Typography level="h4" gutterBottom> {t(`Se ha recuperado un total de ${totalPayment} €.`)} </Typography>)}
            </Box>    
            <Typography level="h4" gutterBottom> {t("Importe recuperado por medio")} </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            
                <PieChart
                    series={[
                        {
                        data: [
                            { id: 0, value: 10, label: 'Mail', color: 'orange' },
                            { id: 1, value: 15, label: 'SMS' },
                            { id: 2, value: 20, label: 'Llamada' },
                        ],
                        },
                    ]}
                    width={400}
                    height={200}
                />
            </Box>                
        </BaseView>
    );
}



