import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

import Template from '../../services/Template';
import Box from '@mui/joy/Box';
import CssBaseline from '@mui/joy/CssBaseline';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, FormLabel, IconButton, Textarea, Typography } from '@mui/joy';
import Breadcrumbs from '../../components/Header/Breadcrumbs';
import BasicAlert from '../../components/Alert';
import PersonIcon from '@mui/icons-material/Person';
import MoneyIcon from '@mui/icons-material/Money';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';


import BaseView from '../BaseView';
import SettingsModal from '../../components/ActionModals/SettingsModal';
import { useAuth0 } from '@auth0/auth0-react';


const drawerWidth = 240;

export default function MailEdit() {
    const { t } = useTranslation();
    const { getAccessTokenSilently } = useAuth0();
    const { id } = useParams();
    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const [mailId, setMailId] = useState<number | undefined>(undefined);
    const [textToInsert, setTextToInsert] = useState<string | undefined>(undefined)
    const [nameTemplate, setNameTemplate] = useState<string | undefined>(undefined);
    const [successAlertStatus, setSuccessAlertStatus] = useState(false);
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [selectedTextField, setSelectedTextField] = useState("body");
    const subjectRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [errorOnUpdate, setErrorOnUpdate] = useState('')

    const saveTemplate = async () => {


        await Template.editMail(getAccessTokenSilently, mailId, nameTemplate, subject, body)
            .then(() => {
                setSuccessAlertStatus(true)
            }).catch((err) => {
                setErrorOnUpdate(err)
            })
    }

    const updateName = async (name:string) => {

        await Template.editMail(getAccessTokenSilently, mailId, nameTemplate)
            .then(() => {
                setNameTemplate(name)
                setOpenSettingsModal(false)
            }).catch((err) => {
                setErrorOnUpdate(err)
            })
    }


    const handleAddText = (id_name: string) => {
        const newValue = `{{${id_name}}}`;

        if (selectedTextField === 'subject' && subjectRef.current) {
            const currentSubjectValue = subjectRef.current.value || '';
            const cursorPosition = subjectRef.current.selectionStart || 0;
            const updatedValueArray = currentSubjectValue.split('');
            updatedValueArray.splice(cursorPosition, 0, newValue);
            const updatedValue = updatedValueArray.join('');
            const newPosition = cursorPosition + newValue.length;
            setSubject(updatedValue);
            setTimeout(() => {
                subjectRef.current?.setSelectionRange(newPosition, newPosition);
                subjectRef.current?.focus();
            }, 0);
        } else if (selectedTextField === 'body') {
            setTextToInsert(newValue);
        }
    };





    React.useEffect(() => {
        if (textToInsert !== undefined) {
            setTextToInsert(undefined);
        }
    }, [textToInsert]);


    React.useEffect(() => {
        setMailId(id ? Number(id) : undefined);
    }, [id]);

    React.useEffect(() => {
        if (mailId !== undefined) {
            const fetchData = async () => {
                try {
                    const data = await Template.getMailbyId(getAccessTokenSilently, mailId );
                    setNameTemplate(data.name)
                    setSubject(data.subject)
                    setBody(data.body)

                } catch (err) {
                    console.error(err);
                }
            };

            fetchData();
        }
    }, [mailId]);


    return (
        <BaseView view="/mail/">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Breadcrumbs elements={[
                    { "name": "Mail", "href": "/mail/" },
                    { "name": nameTemplate! }
                ]} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2">{nameTemplate}</Typography>
                <IconButton aria-label="settings" onClick={() => setOpenSettingsModal(true)}>
                    <SettingsRoundedIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CssBaseline />
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        padding: 5,
                        borderRadius: '4px'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ButtonGroup aria-label="button group">
                                {[{ 'id': 'name', 'name': t('Nombre'), 'icon': <PersonIcon /> }, { 'id': 'surname', 'name': t('Apellido'), 'icon': <PersonIcon /> }, { 'id': 'amount', 'name': t('Cantidad'), 'icon': <MoneyIcon /> }].map((item) => (
                                    <Button onClick={() => handleAddText(item.id)} startDecorator={item.icon}> {item.name}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Box>
                        <Box sx={{ marginTop: 1 }}>
                        </Box>
                        <FormLabel htmlFor="subject">{t("Asunto")}</FormLabel>
                        <Textarea
                            id="subject"
                            placeholder={t("Escriba el asunto") + "..."}
                            value={subject}
                            maxRows={1}
                            onClick={() => setSelectedTextField('subject')}
                            onChange={(e) => setSubject(e.target.value)}
                            slotProps={{ textarea: { ref: subjectRef } }}


                        />
                        <FormLabel sx={{ marginTop: 5 }} htmlFor="body">{t("Cuerpo")}</FormLabel>
                        <Box sx={{ mb: 2 }} onClick={() => setSelectedTextField('body')}>
                            <RichTextEditor
                                value={body}
                                onChange={(newValue) => setBody(newValue)}
                                onInsertText={textToInsert}
                            />
                        </Box>
                        <BasicAlert
                            open={successAlertStatus}
                            severity={'success'}
                            text={t("Plantilla guardada correctamente")}
                            onCloseAlert={() => setSuccessAlertStatus(false)}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', paddingTop: 3 }}>
                            <Button onClick={saveTemplate}>{t("Guardar")}</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {nameTemplate && (
            <SettingsModal
                open={openSettingsModal}
                defaultValue={nameTemplate}
                onClose={() => setOpenSettingsModal(false)}
                onSubmit={updateName}
                error={errorOnUpdate}
            />
            )}
        </BaseView>
    );
}