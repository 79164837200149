import * as React from 'react';
import { useTranslation } from "react-i18next";

import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


export interface DeleteModalProps {
    isOpen: boolean;
    onDelete: (deleted: boolean) => void;
    onClose: () => void;
}



export default function DeleteModal(props: DeleteModalProps) {
    const { t } = useTranslation();

    const { isOpen, onDelete, onClose } = props;
    const [open, setOpen] = React.useState(isOpen);


    const handleClose = () => {
        setOpen(false)
        onClose()
    };
    const handleDelete = () =>  {
        onDelete(true)
        setOpen(false)
        
    }

    React.useEffect(() => {
        setOpen(isOpen)
    }, [isOpen]);


  return (
      <Modal open={open} onClose={() => setOpen(open)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {t("Confirmación")}
          </DialogTitle>
          <Divider />
          <DialogContent>
            {t("¿Seguro que quieres borrar este elemento?")}
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => handleDelete()}>
              {t("Eliminar")}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => handleClose()}>
              {t("Cancelar")}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
  );
}