import { CircularProgress } from '@mui/joy';
import Box from '@mui/material/Box';

export default function LoadingNodeComponent() {

    return (
        <>
            <Box sx={{width:'100%', height:'100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Box>
                <CircularProgress size="sm" variant="soft" color="primary" />

                </Box>
            </Box>
        </>
    )
}