import {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Radio, RadioGroup } from '@mui/joy';


export interface DeleteModalProps {
    isOpen: boolean;
    onDelete: (deleted: boolean, branch: number) => void;
    onClose: () => void;
}



export default function DeleteConditionModal(props: DeleteModalProps) {
    const { t } = useTranslation();

    const { isOpen, onDelete, onClose } = props;
    const [open, setOpen] = useState(isOpen);
    const [branch, setBranch] = useState<number>(0);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value;
        setBranch(parseInt(selectedValue));
      };

    const handleClose = () => {
        setOpen(false)
        onClose()
    };
    const handleDelete = () => {
        onDelete(true, branch)
        setOpen(false)

    }

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen]);


    return (
        <Modal open={open} onClose={() => setOpen(open)}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon />
                    {t("Confirmación")}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {t("Vas a borrar una condición. ¿Qué camino deseas mantener?")}
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                        <FormControl>
                            <FormLabel>Opciones</FormLabel>
                            <RadioGroup
                                name="radio-buttons-group"
                                value={branch.toString()}
                                onChange={handleChange}
                            >
                                <Radio sx={{ m: 2 }} value="1" label={t("Mantener rama izquierda")} size="sm" />
                                <Radio sx={{ m: 2 }} value="0" label={t("Mantener rama derecha")} size="sm" />
                                <Radio sx={{ m: 2 }} value="-1" label={t("Borrar ambas ramas")} size="sm" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="solid" color="danger" onClick={() => handleDelete()}>
                        {t("Eliminar")}
                    </Button>
                    <Button variant="plain" color="neutral" onClick={() => handleClose()}>
                        {t("Cancelar")}
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}