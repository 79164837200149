import * as React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Option from '@mui/joy/Option';
import FormControl from '@mui/joy/FormControl';
import Select from '@mui/joy/Select';
import FormHelperText from '@mui/joy/FormHelperText';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';

import { InfoOutlined } from '@mui/icons-material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import BaseView from './BaseView';
import {getCSRFToken, getAccessToken} from "../utils";
import Breadcrumbs from '../components/Header/Breadcrumbs';
import BasicAlert from '../components/Alert';


const STEP_PHONE = 0
const STEP_OPTION = 1
const STEP_PAY = 2


interface ControlPaymentProps {
    index: number,
    label: string,
    numPayments: number,
    splitAmount: number,
    errorDate?: boolean,
    errorNumPayments?: boolean,
    onChangeDate: (event: dayjs.Dayjs | null) => void
    onChangeNumPayments: (event: React.ChangeEvent<HTMLInputElement>) => void
}
 

function ControlPayment(props: ControlPaymentProps) {
    const { t } = useTranslation();

    const {index, label, numPayments, splitAmount, errorDate=false, errorNumPayments=false, onChangeDate, onChangeNumPayments} = props

    if (index == 0) {
        onChangeDate(dayjs());

        return (
            <Box>
                <Typography>{t("Ha seleccionado la opción de")} <b>{label}</b>.</Typography><br/><br/>
            </Box>
        );
    }
    else if(index == 1){
        return (
            <Box>
                <Typography>{t("Ha seleccionado la opción de")} <b>{label}</b>.</Typography><br/><br/>
                <Typography>{t("Introduzca la fecha deseada para realizar el pago")}:</Typography><br/><br/>
                
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker 
                        disablePast
                        sx={{minWidth: 450}}
                        onChange={onChangeDate}
                        slotProps={{
                            textField: {
                                error: errorDate,
                                helperText: errorDate && t("Seleccione una fecha de pago válida")
                            },
                        }}
                    />
                </LocalizationProvider>
                <br/><br/>
            </Box>
        ); 
    }
    else if(index == 2){
        return (
            <Box>
                <Typography>{t("Ha seleccionado la opción de")} <b>{label}</b>.</Typography><br/><br/>

                <Typography>{t("Introduzca el número de plazos en los que desea fragmentar el pago:")}</Typography><br/><br/>

                <FormControl error={errorNumPayments} style={{width: 450}}>
                    <Input 
                        id="num-payments" 
                        placeholder={t("Número de plazos")}
                        variant="outlined" 
                        onChange={onChangeNumPayments}
                        error={errorNumPayments}
                    />
                    {errorNumPayments && <FormHelperText><InfoOutlined /> {t("Número de plazos necesario")} </FormHelperText>}
                </FormControl>

                {(splitAmount >= 0) && <><Typography>{t("El importe de la deuda se ha dividido en")} <b>{numPayments} {t("plazos")}</b> {t("de")} <b>{splitAmount.toFixed(2)}€</b>.</Typography></>}

                <br/><br/><Typography> {t("Introduzca la fecha deseada para realizar el primer pago")}:</Typography><br/><br/>
                
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker 
                        disablePast
                        sx={{minWidth: 450}}
                        onChange={onChangeDate}
                        slotProps={{
                            textField: {
                                error: errorDate,
                                helperText: errorDate && t("Seleccione una fecha de pago válida")
                            },
                        }}
                    />
                </LocalizationProvider>
                <br/><br/>
            </Box>
        ); 
    }        
    else{     
        return (
            <Box >
                
            </Box>
        ); 
     }


}


export default function PaymentStatus() {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = React.useState(0);
    const [debtorPhone, setDebtorPhone] = React.useState("");
    const [amount, setAmount] = React.useState(0);
    const [debtorId, setDebtorId] = React.useState(-1);    
    
    const [paymentOption, setPaymentOption] = React.useState<number>(0);
    const [paymentDate, setPaymentDate] = React.useState("");
    const [numPayments, setNumPayments] = React.useState<number>(0);
    const [splitAmount, setSplitAmount] = React.useState<number>(-1);

    const [successAlertStatus, setSuccessAlertStatus] = React.useState(false);
    const [errorAlertStatus, setErrorAlertStatus] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isDebtorPhoneInvalid, setIsDebtorPhoneInvalid] = React.useState(false);
    const [isPaymentOptionInvalid, setIsPaymentOptionInvalid] = React.useState(false);
    const [isPaymentDateInvalid, setIsPaymentDateInvalid] = React.useState(false);
    const [isNumPaymentsInvalid, setIsNumPaymentsInvalid] = React.useState(false);

    function savePayment(debtorId: number, debtorDate: string, numPayments: number = 1, interval: number = 0) {
        const token = getAccessToken()
        let url = import.meta.env.VITE_API_URL + '/api/payment/'
        
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json',
                "X-CSRFToken": getCSRFToken()
            },
            body: JSON.stringify({'debtor_id': debtorId, 'date': debtorDate, 'num_payments': numPayments, 'interval': interval})
        }
            fetch(url, options)
            .then((response) => 
            {
                if (!response.ok) {
                    return new Error("Error al hacer el pago");
                }
            }).catch((err) => {
                console.log(err.message);
            });
    }

    function handlePhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "") {
            setIsDebtorPhoneInvalid(true);
        } else {
            setDebtorPhone(event.target.value);
            setIsDebtorPhoneInvalid(false);
            setErrorAlertStatus(false);
        }
    }

    function handlePaymentDateChange(event: dayjs.Dayjs | null) {
        if (event !== undefined) {
            let debtorDate = event!.format("YYYY-MM-DD HH:mm:ssZZ");
            setPaymentDate(debtorDate);
            setIsPaymentDateInvalid(false);
        } else {
            setIsPaymentDateInvalid(true);
        }
    }

    function handleNumPaymentsChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "") {
            setIsNumPaymentsInvalid(true);
            return;
        }

        let value = parseInt(event.target.value);

        if (value <= 0) {
            setIsNumPaymentsInvalid(true);
        } else {
            setNumPayments(value);
            setIsNumPaymentsInvalid(false);

            setSplitAmount(amount / value);
        }
    }

    const handlePaymentOptionChange = (_: React.SyntheticEvent | null, newValue: number | null) => {
        if (newValue !== null && newValue as number >= 0) {
            setPaymentOption(newValue as number);
            setIsPaymentOptionInvalid(false);
            setPaymentDate("");
            setIsPaymentDateInvalid(false);
        } else {
            setIsPaymentOptionInvalid(false);
        }
    };

    const handleNext = () => {
        if (activeStep === STEP_PHONE) {
            if (debtorPhone === "") {
                setIsDebtorPhoneInvalid(true);
                return;
            } else {
                const token = getAccessToken()

                fetch(import.meta.env.VITE_API_URL + `/api/debtor/`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "X-CSRFToken": getCSRFToken(),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        phone: debtorPhone,
                    }),
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(t("Número de teléfono incorrecto"));
                  })
                .then((data) => {
                    setAmount(data["debt_amount"]);
                    setDebtorId(data["user_id"]);
                })
                .catch((err) => {
                    setErrorMessage(err.message);
                    setErrorAlertStatus(true);
                    setActiveStep(STEP_PHONE);
                    setIsDebtorPhoneInvalid(true);
                    return;
                });
            }
        } else if (activeStep === STEP_OPTION) {
            if (paymentOption < 0) {
                setIsPaymentOptionInvalid(true);
                return;
            } else if (paymentOption != 2) {
                setNumPayments(1);
            }
        } else if (activeStep === STEP_PAY) {
            let errorFlag = 0;

            if (numPayments <= 0) {
                setIsNumPaymentsInvalid(true);
                setActiveStep(STEP_PAY);
                errorFlag = 1;
            } 

            if (paymentDate === "") {
                setIsPaymentDateInvalid(true);
                setActiveStep(STEP_PAY);
                errorFlag = 1;
            }
            
            if (errorFlag == 0) {
                savePayment(debtorId, paymentDate, numPayments);
                setSuccessAlertStatus(true);
            } else {
                return;
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSuccessAlertStatus(false);
        setDebtorPhone("");
        setDebtorId(-1);
        setAmount(0);
        setPaymentOption(0);
        setPaymentDate("");
        setNumPayments(0);
        setSplitAmount(-1);
    };

    function isFailedStep(index: number) {
        if (index == STEP_PHONE) {
            return isDebtorPhoneInvalid;
        } else if (index == STEP_OPTION) {
            return isPaymentOptionInvalid;
        } else if (index == STEP_PAY) {
            return isPaymentDateInvalid || isNumPaymentsInvalid;
        }

        return false;
    }

    const info = [
        t("Pagar todo"),
        t("Posponer pago"),
        t("Fragmentar el pago")
    ]

    const steps = [
        {
            label: t("Introduzca el teléfono del deudor"),
            description: 
                <FormControl error={isDebtorPhoneInvalid} style={{width: 450}}>
                    <Input 
                        id="debtor-phone" 
                        placeholder={t("Número de télefono del deudor")}
                        variant="outlined" 
                        onChange={handlePhoneChange}
                        defaultValue={debtorPhone}
                        error={isDebtorPhoneInvalid}
                    />
                    {isDebtorPhoneInvalid && <FormHelperText><InfoOutlined /> {t("Teléfono necesario")} </FormHelperText>}
                </FormControl>
        },
        {
            label: t("Seleccione la opción de pago deseada"),
            description:
                <>
                <Typography>{t("La deuda asciende a un total de")} <b>{amount}€</b>.</Typography><br/><br/>
                <Select
                    id="select"
                    style={{width: 450}}
                    value={paymentOption}
                    onChange={handlePaymentOptionChange}
                >
                    {info.map((option, index) => {
                        return (
                            <Option value={index}>{option}</Option>
                        );
                    })}
                </Select>
                </>,
        },
        {
            label: t("Completar el pago"),
            description: 
                <ControlPayment
                    index={paymentOption}
                    label={info[paymentOption]}
                    numPayments={numPayments}
                    splitAmount={splitAmount}
                    errorDate={isPaymentDateInvalid}
                    errorNumPayments={isNumPaymentsInvalid}
                    onChangeDate={handlePaymentDateChange}
                    onChangeNumPayments={handleNumPaymentsChange}
                />,
        },
    ];


    return (
    <BaseView view="/payment-status/">
        <Breadcrumbs elements={[{'name': t("Pagos")}]} />
        <Box sx={{ width: '100%'}}>
            <BasicAlert open={errorAlertStatus} text={t(errorMessage)} severity="error" onCloseAlert={() => { setErrorAlertStatus(false) }} />
            <BasicAlert open={successAlertStatus} text={t("Pago realizado correctamente")} severity="success" onCloseAlert={() => { setSuccessAlertStatus(false) }} />
            
            <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel error={isFailedStep(index)}>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2, display: "flex" }}>
                                        <Button
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? t("Confirmar") : t("Continuar")}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {t("Atrás")}
                                        </Button>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>{t("Todos los pasos completados.")}</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        {t("Reiniciar")}
                    </Button>
                </Paper>

                
            )}
            
        </Box>
    </BaseView>
    );
}