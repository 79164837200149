//
//
//  Utils
//
//

import { Transition } from "./interfaces"
import { AuthenticationError } from "./services/Api"

export function getCSRFToken(): string {
    const csrfCookieValue = getCookie("csrftoken")
    if (csrfCookieValue != null) {
        return csrfCookieValue
    }

    const csrfMetaValue = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")
    if (csrfMetaValue != null) {
        return csrfMetaValue
    }
    return ""
}

export function getCookie(name: string) {
    const nameEQ = name + '=';
    for (const cookie of document.cookie.split('; ')) {
        if (cookie.indexOf(nameEQ) === 0) {
            const value = cookie.substring(nameEQ.length);
            return decodeURIComponent(value); // returns first found cookie
        }
    }
    return null;
}

export function getAccessToken() {

    const sessionToken = sessionStorage.getItem("token");
  
    if (sessionToken) {
      return sessionToken;
    }
  
    const localStorageToken = localStorage.getItem("token");

    if (localStorageToken) {
      return localStorageToken;
    }

    return null;
  }
  

export function getClientId() {
    const clientId = sessionStorage.getItem("clientId");
    if (clientId) { 
        return parseInt(clientId);
    }
    return undefined
  }


export function setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires}; path=/`;
}

function getBrowserLanguage() {
    let lang = window.navigator.language

    let shortLang = lang
    if (shortLang.indexOf('-') !== -1) {
        shortLang = shortLang.split('-')[0]
    }

    if (shortLang.indexOf('_') !== -1) {
        shortLang = shortLang.split('_')[0]
    }

    return [lang, shortLang]
}

export class RequestStatusFailure extends Error {

    response: any

    constructor(response: any) {
        super('RequestStatusFailure');
        this.response = response
    }

}


export function getCurrentLanguage() {
    let languageCode = document.querySelector("meta[name='language-code']")?.getAttribute("content")
    return languageCode || getBrowserLanguage()[1]
}


export function raiseForStatus(response: Response) {
    if (!response.ok) {
         if (response.status === 401) {
            throw new AuthenticationError()
        }
        throw new RequestStatusFailure(response)
    }
    return response
}

export function toJsonOrRaise(response: Response) {
    if (!response.ok) {
        if (response.status === 401) {
            throw new AuthenticationError()
        }
        throw new RequestStatusFailure(response)
    }

    return response.json()
}


export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};


export const convertTime = (timeString: string) => {
    const timeStringParts = timeString.split(/[:,]/).map(part => part.trim());
    let days = 0;
    let hours = 0;
    let minutes = 0;
  
    if (timeStringParts.length >= 2) {
      if (timeStringParts[0].includes('day')) {
        days = parseInt(timeStringParts[0]);
        hours = parseInt(timeStringParts[1]);
        if (timeStringParts.length >= 3) {
          minutes = parseInt(timeStringParts[2]);
        }
      } else {
        hours = parseInt(timeStringParts[0]);
        if (timeStringParts.length >= 2) {
          minutes = parseInt(timeStringParts[1]);
        }
      }
    }
  
    const daysString = days === 1 ? 'day' : 'days';
    return `${days} ${daysString}, ${hours} hours, ${minutes} minutes`;
  };
  


  export const formatSendTime = (sendTime: { days: number; hours: number; minutes: number }) => {
    const { days, hours, minutes } = sendTime;
  
    if (days > 0 || hours > 0) {
      const totalHours = days * 24 + hours;
      const formattedHours = `${totalHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
      return formattedHours;
    } else {
      const formattedMinutes = `00:${minutes.toString().padStart(2, '0')}:00`;
      return formattedMinutes;
    }
  };


  
//utils to take equivalences: key to value, value to key

interface ModelNameToContentType {
    [key: string]: number; 
}

export const contentTypeToString = (contentTypeMapping: ModelNameToContentType,contentTypeCode: number): string | undefined => {
    for (const [key, value] of Object.entries(contentTypeMapping)) {
      if (value === contentTypeCode) {
        return key;
      }
    }
    return undefined;
  };
  
export const stringToContentType = (contentTypeMapping: ModelNameToContentType, contentTypeString: string): number | undefined => {
    const code = contentTypeMapping[contentTypeString];
    return code !== undefined ? code : undefined; 
  };


export const hasChildren = (transitions: {[key: number]: Transition;}, id: number) => {
    for (const transitionId in transitions) {
        if (transitions.hasOwnProperty(transitionId)) {
            const transition = transitions[transitionId];
            if (transition.src_id === id) {
                return true;
            }
        }
    }
    return false;
};