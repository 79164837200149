import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';

// icons
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsIcon from '@mui/icons-material/Analytics';



import { Toggler } from '../../components/Sidebar/Toggler';
import PermissionsGate from '../../components/PermissionsGate';



export default function HomeSidebar() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const pages: { href: string, name: string, icon: ReactNode, subpages: { href: string, name: string, icon: ReactNode }[] }[] =
        [   
            { href: '/', name: t('Home'), icon: <HomeRoundedIcon />, subpages: [] },
            { href: '/templates/', name: t('Plantillas'), icon: <RateReviewRoundedIcon />, subpages: [{ href: '/mail/', name: t('Email'), icon: <EmailRoundedIcon /> }, { href: '/sms/', name: t('SMS'), icon: <SmsRoundedIcon /> }] },
            { href: '/strategies/', name: t('Estrategias'), icon: <RouteRoundedIcon />, subpages: [] },
            { href: '/campaign/', name: t('Campañas'), icon: <CampaignRoundedIcon />, subpages: [] },
            { href: '/payment-status/', name: t('Pagos'), icon: <CreditCardRoundedIcon />, subpages: [] },
            { href: '/test-send/', name: t('Pruebas envíos'), icon: <PhoneCallbackIcon />, subpages: [] },
            { href: '/statistics/', name: t('Estadísticas'), icon: <AnalyticsIcon />, subpages: [] },
            { href: '/admin/', name: t('Admin'), icon: <AdminPanelSettingsIcon />, subpages: [] }
        ];

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-radius': '6px',
                '--List-gap': '6px',
            }}
        >
            {pages.map((page) => (
                <PermissionsGate target={page.href} operation="">
                    {page.subpages && page.subpages.length > 0 ? (
                        <ListItem nested>
                            <Toggler
                                defaultExpanded={location.pathname === page.href}
                                renderToggle={({ open, setOpen }) => (
                                    <ListItemButton onClick={() => setOpen(!open)}>
                                        <ListItemDecorator>{page.icon}</ListItemDecorator>
                                        <ListItemContent>{page.name}</ListItemContent>
                                        <KeyboardArrowDownIcon
                                            sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                        />
                                    </ListItemButton>
                                )}
                            >
                                <List sx={{ gap: 0.5 }}>
                                    {page.subpages.map((subpage) => (
                                        <PermissionsGate target={subpage.href} operation="">
                                            <ListItem key={subpage.href} sx={{ mt: 0.5 }}>
                                                <ListItemButton selected={location.pathname === subpage.href}  onClick={() => navigate(subpage.href)}>
                                                    {subpage.name}
                                                </ListItemButton>
                                            </ListItem>
                                        </PermissionsGate>
                                    ))}
                                </List>
                            </Toggler>
                        </ListItem>

                    ) : (
                        <ListItem>
                            <ListItemButton
                                selected={location.pathname === page.href}
                                href={page.href}
                                onClick={() => navigate(page.href)}
                            >
                                <ListItemDecorator>{page.icon}</ListItemDecorator>
                                <ListItemContent>{page.name}</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )}
            </PermissionsGate>
            ))}
        </List >
    );
}