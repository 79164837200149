import React, { useEffect, useRef } from 'react';
import { List, ListItem, ListItemText, Paper } from '@mui/material';

interface PhoneMessageProps {
    messages: string[];
}

const PhoneMessage: React.FC<PhoneMessageProps> = ({ messages }) => {

    const containerRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, [messages, containerRef.current]);


    return (

        <>
            <Paper ref={containerRef} sx={{
                    width:'100%',
                    height:'100%',
                    maxHeight: 300,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    padding: 1,
                    borderRadius: '10px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    flex: '0 0 auto'
                }}>
                {messages[0] != '' && (
                    <>
                {messages.map((message, index) => (
                    <Paper  sx={{
                        maxWidth: '60%',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        marginTop: 4,
                        backgroundColor:'#bbdefb',
                        borderRadius:'20px'
                    }} className="phone-container" elevation={3}>
                        <List className="phone-screen">

                            <ListItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word',
                                  }}
                                key={index}>
                                <ListItemText sx={{
                                    wordWrap: 'break-word',
                                    maxHeight: '100%',
                                }} primary={message} />
                            </ListItem>

                        </List>
                    </Paper>

                ))}
                </>
                )}
            </Paper>
        </>
    );
};

export default PhoneMessage;
