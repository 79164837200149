import Stack from '@mui/joy/Stack';
import { Item } from './Item';
import { useState, useEffect } from 'react';
import DeleteModal from '../ActionModals/DeleteModal';


interface ListItemsProps {
    items: { id: number, name: string }[]
    onSelect: (id: number) => void;
    onDelete: (id: number) => void;
}


export default function ListItems(props: ListItemsProps) {

    const { items, onSelect, onDelete } = props
    const [rows, setRows] = useState(items);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined)

    useEffect(() => {
        setRows(items);
    }, [items]);

    const handleDeleteClick = () => {
        if (idToDelete) {
            onDelete(idToDelete)
        }
        setRows(rows.filter((row) => row.id !== idToDelete));
        setDeleteModalOpen(false)
    };

    return (
        <>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {rows.map((row) => (
                    <Item key={row.id} onClick={() => onSelect(row.id)} onDelete={() => {
                        setDeleteModalOpen(true)
                        setIdToDelete(row.id)
                    }}>{row.name}
                    </Item>
                ))}
            </Stack>
            <DeleteModal
                isOpen={deleteModalOpen}
                onClose={()=> setDeleteModalOpen(false)}
                onDelete={() => handleDeleteClick()}
            />
        </>
    );
}
