import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { SmsRounded } from "@mui/icons-material";
import { Divider } from "@mui/joy";


export default function SmsNodeComponent({ text }: { text: string }) {

    return (
        <>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <Box sx={{ mr: 1, display: 'flex' }}>
                <SmsRounded sx={{color: '#fb8c00'}}/>
                </Box>
                <Divider orientation="vertical" />
                <Typography sx={{ flexGrow: 1, textAlign: 'center' }}>
                    {text}
                </Typography>
            </Box>
        </>
    )
}