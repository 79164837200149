import { useTranslation } from "react-i18next";
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import { useState, useEffect } from 'react';
import BasicAlert from '../../Alert';
import DialogTitle from '@mui/joy/DialogTitle';
import Skeleton from '@mui/joy/Skeleton';
import { Link } from 'react-router-dom';
import DialogContent from '@mui/joy/DialogContent';
import PopulatedSelector from '../../PopulatedSelector';
import Tooltip from '@mui/joy/Tooltip';
import ModalClose from '@mui/joy/ModalClose';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PlanificationEvents from '../../../services/Drawflow';
import DeleteModal from '../../ActionModals/DeleteModal';

import Template from '../../../services/Template';
import { useContentType } from "../../../context/contentTypeContext";
import { contentTypeToString } from "../../../utils";
import { useClient } from "../../../context/ClientContext";
import { useAuth0 } from "@auth0/auth0-react";

interface MenuActionProps {
contentTypeId: number | undefined;
planStepId: number | undefined;
defaultActionId: number | undefined;
onDeleted?: () => void;
onUpdated?: (response: any) => void;
onClose?: () => void;
}


export default function MenuAction({ contentTypeId, planStepId, defaultActionId, onDeleted, onUpdated }: MenuActionProps) {
  const { t } = useTranslation();
  const client = useClient()
  const {getAccessTokenSilently} = useAuth0()
  const populatedWidth = 240
  const { contentTypeMapping } = useContentType()


  const [selectedTemplate, setselectedTemplate] = useState<number | undefined>(undefined);
  const [typeMenu, setTypeMenu] = useState<string | undefined>('mail');

  const [actionId, setActionId] = useState(defaultActionId);
  const [alert, setAlert] = useState({ open: false, severity: '', text: '' });
  const [deleteOpen, setDeleteOpen] = useState(false);


  useEffect(() => {
      const typeMenuSelected = contentTypeToString(contentTypeMapping, contentTypeId!)
      setTypeMenu(typeMenuSelected);
      setselectedTemplate(defaultActionId)
    if (typeMenu == 'call') {
      getDefaultCallTemplate()
    }

  }, [contentTypeId, defaultActionId]);


  const actionIdchange = (event: React.SyntheticEvent | null, newValue: string | null) => {
    if (event == null) return;

    if (newValue != null) {
      setActionId(parseInt(newValue))
      setselectedTemplate(parseInt(newValue))
    } 
  }

  const handleCloseAlert = () => {
    setAlert({ open: false, severity: '', text: '' });
  }

  const getDefaultCallTemplate = async () => {
    const data = await Template.getCall(getAccessTokenSilently, client.client!.id)
    setselectedTemplate(data[0].id)
    setActionId(data[0].id)
  }

  const saveItem = async () => {
    
      const response = await PlanificationEvents.editPlanStep(getAccessTokenSilently, planStepId!, {action_id: actionId, content_type_id: contentTypeId, step_type: 'action' })
      onUpdated!(response)
  }

  const deleteItem = () => {
    setDeleteOpen(false)
    onDeleted!()
  }

  const handleOpenDeleteModal = () => {
    setDeleteOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteOpen(false)
  }


  return (
    <>
      <DialogTitle>{t("Configuración")}</DialogTitle>
      <ModalClose />
      <Divider />
      <DialogContent sx={{ gap: 2 }}>
      {typeMenu != 'call' && (
        <>
        <FormLabel sx={{ typography: 'title-md' }}>
          {t("Seleccione una plantilla")}:
        </FormLabel>
        <FormControl orientation="horizontal" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, pr: 1 }}>
            {selectedTemplate && typeMenu ? (
              <PopulatedSelector
                width={populatedWidth}
                url={import.meta.env.VITE_API_URL + `/api/${typeMenu}/?client_id=${client.client!.id}`}
                text_field="name"
                //@ts-ignore
                defaultSelected={selectedTemplate ? selectedTemplate : ''}
                onChange={(event: React.SyntheticEvent | null, newValue: string | null) => actionIdchange(event, newValue)}
                getAccessToken={getAccessTokenSilently}
              />
            ) : (
              <Skeleton variant="rectangular" width={343} height="1em" />
            )}
          </Box>
          <Box sx={{ ml: 1 }}>
            <Tooltip title={t("Ver plantilla")}>
              <IconButton disabled={!selectedTemplate}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Tooltip>
            {selectedTemplate ? (
              <Tooltip title={t("Editar plantilla")}>
                <IconButton
                  component={Link}
                  to={`/${typeMenu}/${selectedTemplate}`}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("Editar plantilla")}>
                <IconButton disabled>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </FormControl>
        </>
        )}
        <BasicAlert
          open={alert.open}
          severity={alert.severity}
          text={alert.text}
          onCloseAlert={handleCloseAlert}
        />
      </DialogContent>
      <Divider sx={{ mt: 'auto' }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        useFlexGap
        spacing={1}
      >
        <Button onClick={() => saveItem()} variant="solid">{t("Guardar")}</Button>
        <Button onClick={() => handleOpenDeleteModal()} variant="plain">{t("Eliminar")}</Button>
      </Stack>
      <DeleteModal
                isOpen={deleteOpen}
                onDelete={() => deleteItem()}
                onClose={() => handleCloseDeleteModal()}
        />
    </>
  );
}
